export default {
  contest: 'Contest',
  practice: 'Practice',
  start: 'Bắt đầu',
  generalInstructions: 'Hướng dẫn chung',
  enroll: 'Tham gia',

  beginner: 'Sơ cấp',
  intermediate: 'Trung cấp',
  advanced: 'Cao cấp',

  general: {
    carefully: 'Đọc hướng dẫn cẩn thận.',
    wordLimit: 'Thực hiện theo giới hạn từ.',
    effectively: 'Quản lý thời gian của bạn một cách hiệu quả.',
    unsure: 'Trả lời tất cả các câu hỏi, ngay cả khi không chắc chắn.',
    answerSheet: 'Viết câu trả lời vào phiếu trả lời.',
    listeningSection: 'Làm theo hướng dẫn cho phần Nghe.',
    beforeListening: 'Đọc câu hỏi trước khi nghe.',
    writingAndSpeaking:
      'Lên kế hoạch cho câu trả lời của bạn trong phần Viết và Nói.',
    speakingSection: 'Đi đúng chủ đề trong phần Nói.',
    testConditions: 'Luyện tập với yêu cầu của bài thi.'
  },

  modalConfirm: {
    confirmation: 'Xác nhận',
    agree: 'Đồng ý',
    continuously:
      'Bài kiểm tra này chỉ được thực hiện một lần. Hãy chắc chắn rằng bạn có đủ thời gian để thực hiện bài kiểm tra này liên tục'
  },

  overView: {
    test: 'Bài thi',
    practice: 'Luyện tập',
    reading: 'Viết',
    listening: 'Nghe',
    speaking: 'Nói',
    witing: 'Đọc'
  }
};

import { getPolicyApi } from 'services/apis/other/policy';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getPolicy = createAsyncThunk(
  'policy/GET',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getPolicyApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  policy: [],
  loading: false,
  error: {}
};

const policySlice = createSlice({
  name: 'policy',
  initialState,

  extraReducers: {
    [getPolicy.pending]: state => {
      state.loading = true;
    },
    [getPolicy.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getPolicy.fulfilled]: (state, action) => {
      state.policy = action.payload.data;
      state.loading = false;
      state.error = {};
    }
  }
});

const { reducer: policyReducer } = policySlice;
export default policyReducer;

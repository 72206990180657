export default {
  contest: 'Contest',
  practice: 'Practice',
  filter: 'Filter',
  start: 'Start',
  generalInstructions: 'General Instructions',
  enroll: 'Enroll',

  beginner: 'Beginner',
  intermediate: 'Intermediate',
  advanced: 'Advanced',

  contestManager: 'Contest Manager',
  result: 'result',
  waiting: 'waiting',

  list: {
    notJoined: 'haven’t joined ({{num}})',
    joined: 'Join ({{num}})',
    textShow: 'Show',
    inputNameTest: 'Enter Test Name',
    inputNamePractice: 'Enter Practice Name'
  },

  modalFilter: {
    level: 'Level',
    skill: 'Skill',
    timeStart: 'Time Start',
    timeEnd: 'Time End',
    type: 'Type',
    reset: 'Reset'
  },

  exam: {
    true: 'true',
    false: 'false',
    notGiven: 'not given',
    yes: 'yes',
    no: 'no',
    ifTrue: 'If the statement agrees with the information',
    ifFalse: 'If the statement contradicts the information',
    ifNG: 'If there is no information on this',
    vocabulary: 'Vocabulary',
    next: 'next question',
    finish: 'Finish',
    record: 'Record',
    submit: 'Submit'
  },

  general: {
    carefully: 'Read instructions carefully.',
    wordLimit: 'Follow the word limit.',
    effectively: 'Manage your time effectively.',
    unsure: 'Answer all questions, even if unsure.',
    answerSheet: 'Write answers on the answer sheet.',
    listeningSection: 'Follow instructions for the Listening section.',
    beforeListening: 'Read questions before listening.',
    writingAndSpeaking: 'Plan your answers in Writing and Speaking.',
    speakingSection: 'Stay within the topic in the Speaking section.',
    testConditions: 'Practice under test conditions.'
  },

  modalConfirm: {
    confirmation: 'Confirmation',
    continuously:
      'This test is only taken once. Please make sure you have enough time to take this test continuously',
    agree: 'Agree'
  },

  modalBreakTime: {
    breakTime: 'BreakTime',
    ready: 'Ready'
  },

  modalFinish: {
    finish: 'Test Finished!',
    view: 'View Results',
    done: `You've just complete`,
    great: 'Great Job!'
  },

  modalSubmit: {
    submit: 'Submit',
    confirm: 'Are you sure you want to submit?',
    cancel: 'Cancel'
  },

  modalVocabulary: {
    vocabulary: 'Vocabulary',
    nodata: 'There is no vocabulary for this part'
  },

  modalSpeaking: {
    rule: 'rules speaking',
    ready: 'Ready'
  },

  overView: {
    test: 'Test',
    practice: 'Practice',
    reading: 'Reading',
    listening: 'Listening',
    speaking: 'Speaking',
    writing: 'Writing'
  }
};

import { css } from 'styled-components';
import media from './breakpoints';

const utility = css`
  :root {
    --bs-primary: #d72323;
  }
  .container {
    padding: 0;
    @media (max-width: 576px) {
      width: 100%;
    }
    @media (min-width: 576px) {
      width: 540px;
    }
    @media (min-width: 768px) {
      width: 720px;
    }
    @media (min-width: 992px) {
      width: 960px;
    }
    @media (min-width: 1200px) {
      width: 1140px;
    }
    @media (min-width: 1400px) {
      width: 1170px;
    }
  }
  .cursorPointer {
    cursor: pointer;
  }

  /* DatePicker */
  .ant-picker {
    border-radius: ${({ theme }) => theme.borderRadius.base};
    padding: 12px;
    box-shadow: none;
    width: 100%;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: ${({ theme }) => theme.colors.primaryButtonColor};
  }
  .ant-picker-panel-container {
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    padding: 16px;
  }
  .ant-picker-header-view button:hover {
    color: ${({ theme }) => theme.colors.primaryButtonColor};
  }
  .ant-picker-content th,
  .ant-picker-content td {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
  .ant-picker-date-panel .ant-picker-content th {
    color: ${({ theme }) => theme.colors.gray3};
  }
  .ant-picker-cell {
    padding: 8px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.primaryButtonColor};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    padding: 0px 4px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: none;
  }
  .ant-picker-footer {
    /* display: none; */
  }
  .ant-picker.ant-picker-disabled {
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
  }
  .ant-picker-today-btn {
    color: ${({ theme }) => theme.colors.primaryButtonColor};

    &:hover {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  /* TimePicker */
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: ${({ theme }) => theme.colors.primaryButtonColor};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    color: ${({ theme }) => theme.colors.white};
  }
  .ant-picker-now > a {
    color: ${({ theme }) => theme.colors.primaryButtonColor};
  }
  .ant-btn-primary {
    border-color: ${({ theme }) => theme.colors.primaryButtonColor};
    background: ${({ theme }) => theme.colors.primaryButtonColor};

    &:hover,
    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.colors.red} !important;
      background: ${({ theme }) => theme.colors.red} !important;
      color: #fff !important;
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: ${({ theme }) => theme.colors.primaryButtonColor};
    border-color: ${({ theme }) => theme.colors.primaryButtonColor};
  }

  .ant-switch-checked {
    background: #d72323;
  }

  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.colors.mainColor};
  }

  .isAnswerUser .ant-radio-checked .ant-radio-inner {
    border-color: #eb222c !important ;
  }

  .isAnswerUser .ant-radio-checked .ant-radio-inner:after {
    background-color: #eb222c;
  }

  .isAnswerUser .ant-radio:hover .ant-radio-inner {
    border-color: #eb222c;
  }

  .ant-pagination {
    text-align: right;
  }
  .ant-pagination .ant-pagination-item,
  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next {
    color: #333;
  }
  .ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination .ant-pagination-item:hover,
  .ant-pagination .ant-pagination-item.ant-pagination-item-active {
    background: ${({ theme }) => theme?.colors?.primaryColor};
    border-radius: 5px;
    border-color: transparent;
  }
  .ant-pagination .anticon svg {
    margin-top: -5px;
  }

  // TREE SELECT
  .e-list-item {
    margin: 8px 0px;
  }

  .e-text-content {
    padding: 10px 0px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
  }
  .ant-form-item {
    margin: 0 0 16px;
  }
  .ant-menu {
    .ant-menu-item,
    .ant-menu-submenu {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.textColor};
      background: transparent;
      .ant-menu-sub.ant-menu-inline {
        background: transparent;
      }
      &:hover,
      &.ant-menu-item-selected {
        color: ${({ theme }) => theme.colors.textColor};
        background: #fafafa;
      }
      &::after {
        left: 0;
        right: auto;
        border-color: ${({ theme }) => theme.colors.mainColor};
      }
    }
    .ant-menu-item,
    .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-submenu .ant-menu-item {
      height: 48px;
      line-height: 48px;
      margin: 0 !important;
      color: ${({ theme }) => theme.colors.textColor};
    }
    .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      color: ${({ theme }) => theme.colors.textColor};
    }
    .ant-menu-submenu .ant-menu-item {
      font-weight: 400;
      padding-left: 35px !important;
    }
  }

  .ck-editor {
    ul > li {
      list-style: circle;
    }

    ol > li {
      list-style: decimal;
    }

    ul,
    ol {
      padding-left: 2rem;
    }
  }

  .ant-select-tree-treenode-selected {
    background-color: #e6f7ff;

    &:hover {
      background-color: #e6f7ff !important;
    }
  }
  .ant-select-tree-treenode {
    min-height: 32px;
    padding: 5px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    &:hover {
      background-color: #f5f5f5;
    }
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper:hover,
  .ant-select-tree
    .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background: none;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
  }

  /* .ant-dropdown-hidden,
  .ant-dropdown-menu-hidden,
  .ant-dropdown-menu-submenu-hidden {
    display: none;
  } */

  .ant-dropdown {
    &.dropdown-header {
      ${media.tablet`
        top: 55px ;
      `}
    }

    &.ant-dropdown-hidden {
      display: none !important;
    }
  }

  .ant-dropdown-menu {
    border-radius: 8px;
  }

  .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown-menu .ant-dropdown-menu-submenu {
    /* border-bottom: 1px solid #80a6c2; */
    /* margin: 0 16px !important; */
    /* height: 56px; */
    font-weight: ${({ theme }) => theme?.fontWeight?.bold};
  }
  .ant-dropdown-menu .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu .ant-dropdown-menu-submenu:hover,
  .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-selected,
  .ant-dropdown-menu
    .ant-dropdown-menu-submenu.ant-dropdown-menu-item-selected {
    background: transparent;
  }
  /* .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 241, 163, 0.1),
        rgba(255, 241, 163, 0.1)
      ),
      #ffffff;
  } */
  .ant-dropdown-menu-item-active {
    color: ${({ theme }) => theme?.colors?.mainColor};
  }

  .ant-dropdown-menu-title-content {
    white-space: nowrap;
  }

  .ant-select-item-empty {
    text-align: center;
  }

  .ant-picker-ranges {
    padding: 0px;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border: 1px solid ${({ theme }) => theme?.colors?.orange};
  }

  .ant-checkbox-inner {
    border: 1px solid #c9c9c9;
  }

  .ant-checkbox-checked:after {
    border: 1px solid ${({ theme }) => theme?.colors?.orange};
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme?.colors?.orange};
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme?.colors?.orange};
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme?.colors?.orange};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme?.colors?.orange};
    border-color: ${({ theme }) => theme?.colors?.orange};
  }

  .ant-checkbox-group {
    display: grid !important;
    /* grid-template-columns: 1fr 1fr; */
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-table-row-expand-icon {
    color: ${({ theme }) => theme?.colors?.orange};
    border: 1px solid ${({ theme }) => theme?.colors?.orange};
    &:hover {
      color: ${({ theme }) => theme?.colors?.orange};
    }
  }

  .ant-upload.ant-upload-disabled {
    content-visibility: hidden;
  }

  .ant-select-selector {
    min-height: 46px;
    max-height: 100px;
    overflow: auto !important;

    .ant-select-selection-placeholder {
      align-self: center;
    }
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  .ant-table-cell {
    .trimtext-cell {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      max-width: 50ch;
      word-break: break-word;
      text-overflow: ellipsis;
    }
  }

  .trimtext {
    overflow-wrap: anywhere;
  }

  .ant-layout-sider-children {
    width: 250px;
  }

  .ant-avatar {
    cursor: pointer;
  }

  .ant-select-item-option-content {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }
  }

  .ant-checkbox-wrapper {
    font-size: 12px;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${({ theme }) => theme?.colors?.primaryColor};
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }

  .ant-radio-checked:after {
    border-color: ${({ theme }) => theme?.colors?.orange};
  }

  .ant-radio-inner {
    border-color: ${({ theme }) => theme?.colors?.orange} !important;

    &::after {
      content: ' ';
      background-color: ${({ theme }) => theme?.colors?.orange} !important;
    }
  }

  .ant-popover-inner-content {
    width: 100%;
    padding: 10px 20px;

    .btn-filter-popover {
      .title-filter {
        font-size: ${({ theme }) => theme?.fontSize?.base};
        font-weight: ${({ theme }) => theme?.fontWeight?.extraBold};
        margin-bottom: 5px;
      }

      .ant-radio-group {
        .ant-radio-inner {
          border-color: ${({ theme }) => theme?.colors?.orange};
        }

        .ant-radio-inner:after {
          background-color: ${({ theme }) => theme?.colors?.orange};
        }
      }

      .btn-filter {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        margin-top: 10px;

        button {
          width: 50%;
          height: 35px;

          font-size: ${({ theme }) => theme?.fontSize?.sm};
          font-weight: ${({ theme }) => theme?.fontWeight?.bold};
        }
      }
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        font-size: ${({ theme }) => theme?.fontSize?.sm};
        font-weight: ${({ theme }) => theme?.fontWeight?.regular};
        line-height: 24px;

        span {
          font-size: ${({ theme }) => theme?.fontSize?.sm};
          font-weight: ${({ theme }) => theme?.fontWeight?.regular};
          line-height: 24px;
        }
      }
    }
  }

  .ant-message-custom-content {
    display: flex;
    line-height: 16px;
  }
`;
export default utility;

import { getListNotificationsApi } from 'services/apis/other/notification';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getNotifications = createAsyncThunk(
  'notifications/GET',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getListNotificationsApi({
        status: 'waiting'
      });
      return res?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: false,
  listNotifications: [],
  unreadNotificationsCount: 0
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,

  reducers: {},

  extraReducers: {
    [getNotifications.pending.toString()]: state => {
      state.loading = true;
    },
    [getNotifications.rejected.toString()]: state => {
      state.loading = false;
    },
    [getNotifications.fulfilled.toString()]: (state, action) => {
      state.listNotifications = action?.payload?.results || [];
      state.unreadNotificationsCount =
        action?.payload?.results?.filter(item => item?.status === 'waiting')
          ?.length || 0;
      state.loading = false;
    }
  }
});

const { reducer: notificationReducer } = notificationSlice;
export default notificationReducer;

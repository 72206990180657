export default {
  titleForgotPassword: 'Forgot password',
  inputEmail: 'Enter Email',
  inputEmailAgain: 'Re-enter Email',
  passwordRetrieval: 'Retrieve password',
  pleaseInputPass: 'Please enter password.',
  pleaseReEnterPass: 'Please re-enter password.',
  passNoSame: 'Password does not matched.',
  pleaseInputNewPass: 'Please enter new password.',
  pleaseComfirmNewPass: 'Please confirm new password.',
  pleaseInputEmail: 'Please enter Email.',
  pleaseInputEmailAgain: 'Please enter a correct Email.',
  pleaseInputNumberPhone: 'Please enter phone number.',
  invalidEmail: 'Invalid Email.',
  pleaseInputEngName: 'Please enter English name.',
  plsDateOfBirth: 'Please enter date of birth.',
  pleaseInputName: 'Please enter full name.',
  maxName: 'Full name does not exceed 255 characters.',
  minPass: 'Minimum length of password is 6 characters.',
  maxPass: 'Password does not exceed 20 characters.',
  minPhone: 'Minimum length of phone number is 9 characters.',
  maxPhone: 'Phone number does not exceed 14 characters.',
  maxEmail: 'Email does not exceed 52 characters.',
  errorPhone: 'Incorrect phone number format.',
  errorURL: 'Incorrect link format.',
  pleaseInputURL: 'Please enter URL.',
  author: 'Please enter author name.',
  bookName: 'Please enter book name.',
  playListName: 'Please enter playlist name.',
  roleName: 'Please enter group name.',
  rolePermissionName: 'Please enter permission name.',
  roleEmail: 'Please enter Email.',
  maxString: 'Please do not enter exceed 255 characters.',
  pleaseInputClassName: 'Please enter class name.',
  pleaseInputClassDesc: 'Please enter description.',
  pleaseInputClassTeacher: 'Please enter teacher name.',
  pleaseInputNameStudent: 'Please enter name.',
  maxDes: 'Description does not exceed 1000 characters.',
  max512: 'Please do not enter exceed 512 characters.',
  max255: 'Please do not enter exceed 255 characters.',
  max100: 'Please do not enter exceed 100 characters.',
  max50: 'Please do not enter exceed 50 characters.',
  plsInputNameFile: 'Please enter file name.',
  plsInputTags: "Please enter file's tag.",
  pleaseInputAddress: 'Please enter address.',
  pleaseInputWorkspace: 'Please enter workspace name.',
  roleFullName: 'Please enter full name.',
  pleaseInputWorkspaceUrl: "Please enter workspace's URL.",
  errorName: 'Please enter a valid full name.',
  errorWorkspaceUrl: "Incorrect workspace's URL format.",
  errorWorkspace: 'Incorrect workspace format.',
  classNameMax: 'Please do not enter exceed 50 characters.',
  classDescMax: 'Please do not enter exceed 255 characters.',
  classTeacherMax: 'Please do not enter exceed 50 characters.',
  validCode: 'Please enter code',
  minCodeQr: 'Minimum length of code is 6 characters',
  maxCodeQr: 'Code does not exceed 6 characters.',
  plsInputLessonName: 'Please enter lesson name.'
};

export default {
  resetPassword: 'Reset password',
  newpassword: 'New password',
  inputnewpassword: 'Enter new password',
  update: 'Update',

  wrongPasswordFormat: 'Wrong password format',
  pleaseInputPass: 'Please input password',
  passWordDoNotMatch: 'New password do not match',
  changePassword: 'Password change failed'
};

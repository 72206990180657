export default {
  accountSettings: 'Account settings',

  navigation: {
    profileSettings: 'Profile Settings',
    historyContest: 'History Contest',
    changePassword: 'Change Password',
    notifications: 'Notifications'
  },

  profileSettings: {
    profileSettings: 'Profile Settings',
    basicInformation: 'Basic Information',
    name: 'Name',
    phoneNumber: 'Phone number',
    email: 'Email',
    class: 'Class',
    entranceScore: 'Entrance score',
    reading: 'Reading',
    listening: 'Listening',
    speaking: 'Speaking',
    writing: 'Writing',
    cancel: 'Cancel',
    saveChanges: 'Save changes',
    noInformationChange: 'No information to change'
  },

  historyContest: {
    historyContest: 'History contest',
    name: 'Name',
    listening: 'Listening',
    reading: 'Reading',
    speaking: 'Speaking',
    writing: 'Writing',
    overall: 'Overall',
    status: 'Status',
    time: 'Time',
    submittedAt: 'Submitted At',
    action: 'Action',
    enrolled: 'Enrolled',
    unenroll: 'Unenroll'
  },

  changePassword: {
    changePassword: 'Change password',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    enterOldPass: 'Enter old password',
    enterNewPass: 'Enter new password',
    confirmNewPass: 'Confirm new password',
    pleaseInputOldPass: 'Please input old password',
    maxPass: 'Password is not exceeded 64 characters',
    minPass: 'Password contains at least 6 characters',
    pleaseInputPass: 'Please input new password',
    wrongPasswordFormatMax: 'Password is not exceeded 20 characters',
    wrongPasswordFormatMin: 'Password contains at least 6 characters',
    changePasswordFalied: 'Password change failed',
    changePasswordSuccess: 'Change passwords success',
    passWordDoNotMatch: 'New password do not match',
    invalidPass: 'Password without spaces'
  },

  notifications: {
    notifications: 'Notifications',
    textShow: 'Show',
    add_student: 'Enroll',
    start_exam: 'Contest',
    finish_exam: 'Reminder',
    result: 'Results',
    warning_grading: 'Reminder',
    student_submit_exam: 'Submission'
  }
};

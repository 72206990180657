export const API_SERVER = process.env.REACT_APP_SERVER_API;
export const ASSETS_URL = process.env.REACT_APP_SERVER_API;
export const ACCESS_TOKEN = '_ac_tk';
export const REFRESH_TOKEN = '_rf_tk';
export const USER_INFO = '_user_info';
export const REFRESH_TOKEN_EXPIRED_STATUS_CODE = 403;
export const FORBIDDEN_STATUS_CODE = 403;
export const TOKEN_EXPIRED_STATUS_CODE = 401;
export const TYPE_DRAG_TABLE = 'DraggableBodyRow';
export const BETA_ACCESS_STATUS = process.env.REACT_APP_BETA_ACCESS;
export const ROLE_STUDENT = 'student';

export const FIREBASE_KEY_PAIR = process.env.REACT_APP_FIREBASE_KEY_PAIR;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export default {
  test: '12',
  detailClass: 'Detail Class',
  findStudent: 'Enter Student Name / Email / Phone',
  list: {
    classRoom: 'Class Room',
    find: 'Enter Class Name',
    no: 'No',
    name: 'Name',
    image: 'Image',
    quantity: 'Quantity',
    create: 'Date Created',
    addNew: 'ADD NEW'
  },
  create: {
    edit: 'Edit',
    createNew: 'Add New Class',
    className: 'Class Name',
    pleaseEnterName: 'Please enter class name',
    nameIsNot: 'Class name is not exceeded 256 characters',
    placeHolderName: 'Enter class name',
    image: 'Image',
    uploadImage: 'Upload Image',
    required: 'Max 1 image, jpg, png format',
    index: 'Index',
    pleaseEnterIndex: 'Please enter index',
    placeHolderIndex: 'Enter index',
    cancel: 'Cancel',
    add: 'Add'
  },

  delete: {
    deleteClass: 'Delete Class',
    delete: 'Delete',
    contentConfirmDelete: 'Are you sure you want to delete this class?'
  },

  folder: {
    addFolder: 'Add folder',
    cancel: 'Cancel',
    add: 'Add',
    error: 'Server error'
  }
};

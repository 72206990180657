import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';

import { getNotifications } from 'store/other/notificationSlice';

const Notification = ({ payload }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (payload) {
      dispatch(getNotifications());

      const data = {
        ...payload,
        notification: {
          title: payload?.notification?.title,
          body: payload?.notification?.body
        }
      };

      const handlePlayAudio = () => {
        const audio = new Audio('/notification.mp3');

        audio.play();
      };

      const handlePushNotification = () => {
        const btn = document.createElement('button');
        btn.id = `btn-notify-${payload?.id}`;
        btn.onclick = function () {
          handlePlayAudio();
        };
        document.body.appendChild(btn);

        btn.click();

        document.body.removeChild(btn);

        notification['info']({
          placement: 'topRight',
          duration: 5,
          message: data?.notification?.title
          // description: data?.notification?.body
        });
      };

      handlePushNotification();
    }
  }, [dispatch, payload]);

  return <></>;
};

export default Notification;

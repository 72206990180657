const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  forbiden: false
};

const permissionSlice = createSlice({
  name: 'permission',
  initialState,

  reducers: {
    setForbiden: (state, action) => {
      state.forbiden = action.payload;
    }
  }
});

export const { setForbiden } = permissionSlice.actions;
const { reducer: permissionReducer } = permissionSlice;
export default permissionReducer;

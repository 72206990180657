export default {
  email: 'Email',
  placeholderEmail: 'Enter Email',
  password: 'Password',
  confirmPassword: 'Confirm password',
  placeholderPassword: 'Enter password',
  phoneNumber: 'Phone number',
  placeholderPhone: 'Enter phone number',
  submit: 'REGISTER',
  haveAccount: 'You have an account',
  loginHere: 'Login here',
  registerFailed: 'Registered failed.',
  registerComplete: 'Registered successfully.'
};

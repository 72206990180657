export default {
  documents: 'Tài liệu',
  employee: 'Nhân viên',
  questions: 'Câu hỏi',
  folders: 'Thư mục',
  contest: 'Đề thi',
  logOut: 'Đăng xuất',
  sureComfirm: 'Bạn có muốn đăng xuất không?',
  settings: 'Cài đặt',
  class: 'Lớp học'
};

import styled from 'styled-components';
import { Modal as BaseModal } from 'antd';

export const Modal = styled(BaseModal)`
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-header {
    margin: 0px !important;
    background-color: ${({ theme }) => theme?.colors?.white} !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: none;

    .ant-modal-title {
      color: ${({ theme }) => theme?.colors?.blueNew};
    }
  }

  svg {
    color: #ffffff;
  }

  .ant-modal-title {
    text-transform: capitalize;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #ffffff;
    font-style: bold;
    text-align: center;
    font-weight: 700;
    width: ${({ $widthTitle }) => $widthTitle || 'auto'};
    margin: ${({ $margin }) => $margin || 'auto'};
  }
  .ant-modal-body {
    padding-top: 0;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      color: #000000;
    }

    button {
      color: ${({ theme }) => theme?.colors?.textGray};

      :last-child {
        background-color: ${({ theme }) => theme?.colors?.yellow2};
      }
    }
  }
  .ant-modal-title {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #ffffff;
    font-style: bold;
    text-align: center;
  }
  .ant-modal-header {
    margin: 0px !important;
    background-color: #000000;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: none;
  }
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 24px;

  button {
    font-size: ${({ theme }) => theme?.fontSize?.sm};
  }
`;

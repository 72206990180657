import React, { Suspense } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

// import webCookiesStorage from 'utils/webCookiesStorage';
// import { ROUTES_AUTH } from 'routes/constants';

import Layout from 'components/LayoutAuth';
import Loading from 'components/Loading';

const AuthRoute = ({ component: Component, ...rest }) => {
  // const dispatch = useDispatch();
  // const history = useHistory();

  // const { isFirstAccess, isAuth } = useSelector(state => state.authReducer);

  // const accessToken = webCookiesStorage.getToken();
  // const isFirstLogin = localStorage.getItem('isFirstLogin');

  // useEffect(() => {
  //   if (isFirstAccess) {
  //     if (accessToken) {
  //       dispatch(getMe());
  //     } else {
  //       dispatch(updateIsFirstAccess());
  //     }
  //   }
  // }, [accessToken, dispatch, isFirstAccess]);

  // useEffect(() => {
  //   if (!isAuth && !isFirstAccess) window.location.replace(ROUTES_AUTH.LOGIN);
  // }, [isAuth, isFirstAccess]);

  // useEffect(() => {
  //   if (accessToken && isFirstLogin === '1') history.push(ROUTES.UPDATE_EMAIL);
  // }, [accessToken, history, isFirstLogin]);

  // if (isFirstAccess) return <Loading />;

  // useEffect(() => {
  //   if (!accessToken) window.location.replace(ROUTES_AUTH.LOGIN);
  // }, [accessToken]);

  return (
    <Route {...rest}>
      <Layout>
        <Suspense fallback={<Loading />}>
          <Component />
        </Suspense>
      </Layout>
    </Route>
  );
};
export default AuthRoute;

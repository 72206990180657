export default {
  email: 'Email',
  plhuseEmail: 'Enter your email',
  pass: 'Password',
  plhPass: 'Enter password',
  remember: 'Login remember',
  forgotPass: 'Forgot password?',
  submit: 'LOG IN',
  errorShow: 'Username or password is incorrect, please try again.',
  haveAcc: "Don't have an account",
  loginHere: 'Register here',
  loginSucces: 'Login successfully.',
  confirm: 'Confirm',
  numberPhone: 'Phone number',
  plInputNumberPhone: 'Please enter phone number.',

  wrongPasswordFormat: 'Wrong password format',
  pleaseInputPass: 'Please enter your password',
  pleaseInputEmail: 'Please enter your Email',
  pleaseInputEmailAgain: 'Please enter a correct Email.',
  loginFailed: 'Login failed',

  pleaseEnterEmail: 'Please enter your email',
  maxEmail: 'Email is not exceeded 50 characters',
  invalidEmail: 'Invalid email',

  maxPass: 'Password is not exceeded 64 characters',
  minPass: 'Password contains at least 6 characters'
};

import request from 'services/request';

export const getListPermissionGroupApi = async params =>
  request({
    url: '/api/permission-groups/',
    method: 'GET',
    params,
    enableFlashMessageSuccess: false,
    enableFlashMessageError: false
  });

export const getListPermissionGroupBasicApi = async () =>
  request({
    url: '/api/permission-groups/basic/',
    method: 'GET',
    enableFlashMessageSuccess: false,
    enableFlashMessageError: false
  });

export const getPermissionTemplateApi = async () =>
  request({
    url: '/api/permission/default-permission/',
    method: 'GET',
    enableFlashMessageError: false,
    enableFlashMessageSuccess: false
  });

export const getMyPermissionApi = async () =>
  request({
    url: '/api/permission/my-permission/',
    method: 'GET',
    enableFlashMessageError: false,
    enableFlashMessageSuccess: false
  });

export const createPermissionGroupApi = async data =>
  request({
    url: `/api/permission-groups/`,
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const updatePermissionGroupApi = async (id, data) =>
  request({
    url: `/api/permission-groups/${id}/`,
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const getPermissionGroupDetailsApi = async id =>
  request({
    url: `/api/permission-groups/${id}/`,
    method: 'GET',
    enableFlashMessageSuccess: false,
    enableFlashMessageError: false
  });

export const deletePermissionGroupApi = async id =>
  request({
    url: `/api/permission-groups/${id}/`,
    method: 'DELETE',
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const getPermissionListUserApi = async (id, params) =>
  request({
    url: `/api/permission-groups/${id}/staff/`,
    method: 'GET',
    params,
    enableFlashMessageSuccess: false,
    enableFlashMessageError: false
  });

export const deleteUserFromPermissionApi = async (id, data) =>
  request({
    url: `/api/permission-groups/${id}/delete-staff/`,
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: false
  });

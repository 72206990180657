import styled from 'styled-components';
import { Layout as La } from 'antd';

const { Header } = La;

export const Layout = styled(La)`
  min-height: 100vh;
  background: #ffffff;
  .ant-dropdown {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Hd = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  &.ant-layout-header {
    background: #ffffff !important;
    width: 100%;
    padding: 0 0;
    height: 60px;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06) !important;
  }
`;

export const DFlex = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

import React, { Suspense, useEffect } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import webCookiesStorage from 'utils/webCookiesStorage';
import { ROUTES_AUTH } from 'routes/constants';
import { USER_INFO } from 'constants/configs';
import { ROLE } from 'utils/constants/enum';

import Layout from 'components/LayoutUser';
import Loading from 'components/Loading';
import Forbiden from 'components/commons/Forbiden';

StudentRoute.propTypes = {
  component: PropTypes.any
};

function StudentRoute({ component: Component, ...rest }) {
  const accessToken = webCookiesStorage.getToken();

  const userInfo = webCookiesStorage.get(USER_INFO);

  useEffect(() => {
    if (!accessToken) {
      window.location.replace(ROUTES_AUTH.LOGIN);
    }
  }, [accessToken]);

  return (
    <Route {...rest}>
      {userInfo?.role === ROLE.student ? (
        <Layout>
          <Suspense fallback={<Loading />}>
            <Component />
          </Suspense>
        </Layout>
      ) : (
        <Forbiden />
      )}
    </Route>
  );
}

export default StudentRoute;

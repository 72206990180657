export default {
  advanced: 'Nâng cao',
  intermediate: 'Trung cấp',
  beginner: 'Mới bắt đầu',
  ended: 'Đã kết thúc',
  ongoing: 'Đang diễn ra',
  draft: 'Bản nháp',
  private: 'Riêng tư',
  public: 'Công khai',
  undone: 'Chưa hoàn thành',
  done: 'Đã hoàn thành',
  completed: 'Đã hoàn thành',
  writing: 'Viết',
  speaking: 'Nói',
  reading: 'Đọc',
  listening: 'Nghe',
  marked: 'Marked',
  unmarked: 'Unmarked'
};

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './other/authSlice';
import userOverviewReducer from './user/overviewSlice';
import layoutReducer from './other/layoutSlice';
import noPermissionReducer from './other/noPermissionSlice';
import policyReducer from './other/policySlice';
import userStudyForTestReducer from './user/studyForTestSlice';
import userContestReducer from './user/homeContestSide';
import userContestEnteredReducer from './user/profileSlice';
import newsUserReducer from './user/userNewsSlice';
import donorReducer from './user/donorSilce';
import introduceReducer from './other/introduceSlice';
import permissionReducer from './other/permissionSlice';
import questionReducer from './admin/questionSlice';
import examReducer from './user/exam';
import notificationReducer from './other/notificationSlice';

export const store = configureStore({
  reducer: {
    layoutReducer,
    authReducer,
    questionReducer,
    userOverviewReducer,
    noPermissionReducer,
    policyReducer,
    userStudyForTestReducer,
    userContestReducer,
    userContestEnteredReducer,
    newsUserReducer,
    donorReducer,
    introduceReducer,
    permissionReducer,
    examReducer,
    notificationReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

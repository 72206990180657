export default {
  documents: 'Documents',
  employee: 'Employees',
  questions: 'Questions',
  folders: 'Folders',
  contest: 'Contests',
  logOut: 'Log out',
  sureComfirm: 'Do you want to log out?',
  student: 'Students',
  settings: 'Settings',
  youDontHavePermission: "You Don't Have Permission",
  back: 'Back',
  class: 'Classroom',
  leaderBoard: 'Leaderboard'
};

export default {
  stt: 'No',
  employeeManager: 'Employees',
  nameEmployee: 'Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  enterEmployee: 'Enter Employee Name / Email / Phone ',
  addNew: 'Add New',
  addEmployee: 'Add new',
  enterName: 'Enter name',
  actions: 'Actions',
  enterEmail: 'Enter email to login',
  enterPhoneNumber: 'Enter phone number',
  passwordDefault: ' DEFAULT PASSWORD: tes@2023',
  addNewEmployee: 'Add new employee',
  deleteEmployee: 'Delete employee',
  doYouWant: 'Do you want to delete this employee?',
  editEmployee: 'Edit employee',
  edit: 'Edit',
  pleaseEnterName: 'Please enter name',
  nameIsNot: 'Name is not exceeded 256 characters',
  pleaseEnterEmail: 'Please enter email',
  pleaseEnterPhone: 'Please enter phone number',
  enterAValid: 'Enter a valid email address!',
  minPhone: 'Cell phone with at least 10 numbers',
  maxPhone: 'Mobile phone maximum 11 numbers',
  wrongPhoneFormat: 'Invalid phone number',
  addEmployeeFalied: 'Add employee falied',
  addEmployeeSuccess: 'Add employee success',
  getListFalied: 'Get list employee failed',
  editEmployeeFalied: 'Edit employee falied',
  maxEmail: 'Email is not exceeded 50 characters',
  invalidEmail: 'Invalid email',
  permission: 'Permission'
};

export default {
  settings: 'Settings',
  error: 'Error',

  navigation: {
    permissions: 'Permissions',
    permissionsGroup: 'Permissions Group',
    notifications: 'Notifications'
  },

  permissions: {
    permissions: 'Permissions',
    settingsManagement: 'Settings Management',
    addPermissions: 'Add Permissions',
    name: 'Name',
    account: 'Account',
    action: 'Action',
    cannotDeletePermisionGroup:
      'Cannot delete permission groups that contain employees'
  },

  notifications: {
    notifications: 'Notifications',
    textShow: 'Show',
    add_student: 'Enroll',
    warning_grading: 'Reminder',
    student_submit_exam: 'Submission',
    start_exam: 'Contest',
    finish_exam: 'Reminder'
  },

  modalAddNew: {
    addNewPermissions: 'Add new permissions',
    permissions: 'Permissions',
    all: 'All',
    save: 'Save',
    updatePermissions: 'Update permissions'
  },

  modalEdit: {
    name: 'Name',
    phoneNumber: 'Phone Number',
    action: 'Action',
    listAccount: 'List account',
    findName: 'Find name, phone number'
  },

  confirmDel: {
    deletePermissions: 'Delete permissions',
    delete: 'Do you want delete permissions',
    confirmDelete: 'Delete'
  }
};

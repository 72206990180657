export default {
  USERPROFILE__EMAIL__EMPTY: 'Email trống',
  USERPROFILE__EMAIL__REQUIRED: 'Vui lòng nhập email',
  USERPROFILE__EMAIL__LENGTH: 'Email quá dài',
  USERPROFILE__EMAIL__INVALID_FORMAT: 'Email sai định dạng',
  EXTERNAL_APP_CONFIG__RESTORE__SUCCESS: 'Khôi phục thành công',
  EXTERNAL_APP_CONFIG__WORKSPACE__EXISTS: 'Đã tồn tại',
  EXTERNAL_APP_CONFIG__CREATE__SUCCESS: 'Tạo thành công',
  ROLE__OWNER__CAN_NOT_UPDATE: 'Không thể cập nhật vai trò',
  ROLE__OWNER__CAN_NOT_DELETE: 'Không thể xóa vai trò',
  ROLE__HAS_STAFF__CAN_NOT_DELETE: 'Không thể xóa vai trò có nhân viên',
  ROLE__PERMISSIONS__INVALID: 'Quyền không hợp lệ',
  INVITATION_CODE__RESOURCE__TO_MUCH: 'Dữ liệu không hợp lệ',
  INVITATION_CODE__BOOK__EXISTS: 'Mã đã tồn tại',
  INVITATION_CODE__COURSE_ID__INVALID: 'Mã khóa học không hợp lệ',
  INVITATION_CODE__STUDENT_ID__INVALID: 'Mã học sinh không hợp lệ',
  INVITATION_CODE__RESOURCE__INVALID: 'Dữ liệu không hợp lệ',
  CODE__REQUIRED: 'Hãy nhập mã',
  STAFF__ROLES_OWNER__REQUIRED: 'Cần quyền chủ',
  STAFF__ROLES__INVALID: 'Vai trò không hợp lệ',
  RESEND__EMAIL__SUCCESS: 'Gửi thành công',
  STAFF__UPDATE_PERMISSIONS__SUCCESS: 'Cập nhật thành công',
  STAFF__PERMISSIONS__INVALID: 'Quyền không hợp lệ',
  STAFF__DELETE_PERMISSIONS__SUCCESS: 'Xóa thành công',
  STAFF__UPDATE_ROLES__SUCCESS: 'Cập nhật thành công',
  STAFF__DELETE_ROLES__SUCCESS: 'Xóa thành công',
  STAFF__FULL_NAME__EMPTY: 'Tên trống',
  STAFF__FULL_NAME__REQUIRED: 'Nhập tên',
  STAFF_INVITATION__EMAIL__EMPTY: 'Email trống',
  STAFF_INVITATION__EMAIL__REQUIRED: 'Vui lòng nhập email',
  STAFF_INVITATION__EMAIL__LENGTH: 'Email quá dài',
  STAFF_INVITATION__EMAIL__INVALID_FORMAT: 'Email sai định dạng',
  USER__EMAIL__NOT_SET: 'Người dùng chưa có email',
  STAFF_INVITATION__ACCEPT__ACCEPTED: 'Đã xác nhận trước đó',
  STAFF_INVITATION__ACCEPT__EXPIRE: 'Hết hạn xác nhận',
  STAFF_INVITATION__ACCEPT__SUCCESS: 'Chấp nhận thành công',
  STAFF_INVITATION__ACCEPT__FAILED: 'Chấp nhận thất bại',
  BETA_INFO__REGISTER__SUCCESS: 'Đăng kí thành công',
  BETA_INFO__EMAIL__EMPTY: 'Email trống',
  BETA_INFO__EMAIL__REQUIRED: 'Vui lòng nhập email',
  BETA_INFO__EMAIL__LENGTH: 'Email quá dài',
  BETA_INFO__EMAIL__INVALID_FORMAT: 'Email sai định dạng',
  CONTACT__CREATE__SUCCESS: 'Gửi thành công',
  CONTACT__EMAIL__EMPTY: 'Email trống',
  CONTACT__EMAIL__REQUIRED: 'Vui lòng nhập email',
  CONTACT__EMAIL__LENGTH: 'Email quá dài',
  CONTACT__EMAIL__INVALID_FORMAT: 'Email sai định dạng',
  COURSES__STUDENT_ID__INVALID: 'Học sinh không đúng',
  COURSES__LEARNING_STATUS__INVALID: 'Trạng thái học không hợp lệ',
  COURSES__ACTIVE_STATUS__INVALID: 'Trạng thái không hợp lê',
  COURSES__UPDATE__SUCCESS: 'Cập nhật thành công',
  TRACKING__DATE_INPUT__INVALID: 'Ngày không hợp lệ',
  COURSE_LESSONS__ID__INVALID: 'Bài học không hợp lệ',
  COURSE_LESSONS__UPDATE_ORDER__SUCCESS: 'Sắp xếp thành công',
  COURSE_LESSONS__INPUT__INVALID: 'Không hợp lệ',
  COURSES_LESSON_DOCUMENT__ASSETS_ID__REQUIRED: 'Tài liệu trống',
  COURSES_LESSON_DOCUMENT__ACTION_INVALID: 'Hành động không hợp lệ',
  COURSES_LESSON_DOCUMENT__ASSETS_ID__INVALID: 'Tài liệu không hợp lệ',
  COURSES_LESSON__LEARNING_CONTENT__EMPTY: 'Nội dung trống',
  COURSES_LESSON__STATUS__INVALID: 'Trạng thái không hợp lệ',
  COURSES_LESSON__END_AT__INVALID: 'Thời gian kết thúc không hợp lệ',
  COURSES_LESSON__START_AT__INVALID: 'Thời gian bắt đầu không hợp lệ',
  COURSES_LESSON__NAME__UNIQUE: 'Tên đã tồn tại',
  COURSES_LESSON__NAME__EMPTY: 'Tên trống',
  COURSES_LESSON__NAME__REQUIRED: 'Vui lòng nhập tên',
  COURSES__CODE__INVALID: 'Mã không hợp lệ',
  COURSES__CODE__NOT_FOR_STUDENT: 'Mã không dùng cho học sinh này',
  CLASS__JOIN__SUCCESS: 'Tham gia thành công',
  CLASS__JOIN__FAILED: 'Tham gia thất bại',
  COURSE__STATUS__INVALID: 'Trạng thái khóa học không đúng',
  BOOK__USERPROFILE_ID__INVALID: 'Người đọc không đúng',
  BOOK__ADD_READER__SUCCESS: 'Thêm người đọc thành công',
  BOOK__DELETE_READER__SUCCESS: 'Xóa người đọc thành công',
  ASSETS__SHARING__DISABLE: 'Chia sẻ tạm khóa',
  PLAYLIST__NAME__REQUIRED: 'Vui lòng nhập tên playlist',
  PLAYLIST__NAME__EMPTY: 'Tên playlist trống',
  PLAYLIST__CODE__UNIQUE: 'Code trùng',
  PLAYLIST__NAME__UNIQUE: 'Tên trùng',
  ASSETS__INVITATION_CODE__EXISTS: 'Code đã tồn tại',
  ASSETS__SHARING__EMPTY: 'Chia sẻ không hợp lệ',
  ASSETS__PATH_FILE__TOO_LARGE: 'File quá lớn',
  ASSETS__APP_TYPE__INVALID: 'App type không hợp lệ',
  ASSETS__PARENT__INVALID: 'Thư mục không đúng',
  REGISTER__INFORMATION__SUCCESS: 'Đăng kí thành công',
  INPUT__ADMIN_TOKEN__REQUIRED: 'Vui lòng nhập admin token',
  INPUT__WORKSPACE_DISPLAY_NAME__REQUIRED: 'Vui lòng nhập workspace name',
  INPUT__WORKSPACE_NAME__REQUIRED: 'Vui lòng nhập workspace',
  INPUT__OWNER_EMAIL__REQUIRED: 'Vui lòng nhập email',
  INPUT__FULL_NAME__REQUIRED: 'Vui lòng nhập tên',
  INPUT__OWNER_PHONE_NUMBER__REQUIRED: 'Vui lòng nhập số điện thoại',
  INPUT__OWNER_PASSWORD__REQUIRED: 'Vui lòng nhập mật khẩu',
  INPUT__ADMIN_TOKEN__EMPTY: 'Admin token trống',
  INPUT__WORKSPACE_DISPLAY_NAME__EMPTY: 'Workspace name trống',
  INPUT__WORKSPACE_NAME__EMPTY: 'Workspace trống',
  INPUT__OWNER_EMAIL__EMPTY: 'Email trống',
  INPUT__FULL_NAME__EMPTY: 'Tên trống',
  INPUT__OWNER_PHONE_NUMBER__EMPTY: 'Số điện thoại trống',
  INPUT__OWNER_PASSWORD__EMPTY: 'Mật khẩu trống',
  INPUT__OWNER_EMAIL__INVALID: 'Định dạng email không hợp lệ',
  INPUT__OWNER_PHONE_NUMBER__UNIQUE: 'Số điện thoại đã tổn tại',
  INPUT__ADMIN_TOKEN__INVALID: 'Admin token không đúng',
  INPUT__WORKSPACE_NAME__UNIQUE: 'Workspace đã tồn tại',
  USER__EMAIL__UNINVITED: 'Tài khoản chưa dược mời tham gia hệ thống',
  USER__ACCOUNT__DEACTIVATED: 'Tài khoản bị vô hiệu hóa',
  USER__LOGIN__UNAVAILABLE: 'Chức năng đăng nhập tạm không khả dụng',
  USER__VERIFY_KEY__BLOCK: 'Đăng nhập quá số lần cho phép',
  USER__DELETE__FAILED: 'Xóa tài khoản thất bại',
  USER_DELETE_SUCCESS: 'Xóa tài khoản thành công',
  ROLE__NAME__REQUIRED: 'Hãy nhập tên',
  ROLE__NAME__EMPTY: 'Tên trống',
  ROLE__NAME__UNIQUE: 'Tên đã tồn tại',
  ROLE__NAME__INVALID_ROLE_NAME: 'Tên không hợp lệ',
  STAFF__ROLE__CAN_NOT_DELETE: 'Không thể xoá vai trò',
  PLAYLIST__ASSETS_ID__INVALID: 'ID tập tin không đúng',
  USER__SUID__INVALID: 'SUID không hợp lệ',
  USER__FIRST_NAME__EMPTY: 'Vui lòng không để trống tên',
  USER__FIRST_NAME__REQUIRED: 'Vui lòng nhập tên',
  STUDENT__DELETE__SUCCESS: 'Xóa thành công',
  STUDENT__NAME__EMPTY: 'tên trống',
  STUDENT__NAME__REQUIRED: 'Hãy nhập tên',
  STUDENT__DOB__INVALID_FORMAT: 'Định dạng ngày không hợp lệ',
  USER__BIRTHDAY__INVALID: 'Ngày không hợp lệ',
  USER__ACCOUNT__INCORRECT: 'Tài khoản hoặc mật khẩu không đúng.',
  USER__PHONE_NUMBER__REQUIRED: 'Vui lòng nhập số điện thoại.',
  USER__PHONE_NUMBER__INVALID: 'Số điện thoại không tồn tại trên hệ thống.',
  USER__PHONE_NUMBER__INCORRECT: 'Số điện thoại chưa được xác nhận',
  USER__VERIFY_KEY__SENDING: 'Gửi mã code thành công',
  USER__CREATE__SUCCESS: 'Đăng ký thành công',
  USER__PASSWORD__INVALID: 'Mật khẩu không đúng',
  USER__EMAIL__INVALID: 'Vui lòng nhập đúng địa chỉ Email.',
  USER__ACCOUNT__DEACTIVATE: 'Tài khoản đã bị vô hiệu hóa.',
  USER__UPDATE__SUCCESS: 'Cập nhật thành công',
  USER__VERIFY_KEY__EXPIRED: 'Mã xác thực hết hạn! Vui lòng nhập mã khác.',
  USER__VERIFY_KEY__INCORRECT: 'Mã xác thực không đúng! Vui lòng nhập mã khác.',
  USER__AVATAR__REQUIRED: 'Vui lòng chọn avatar',
  ASSETS__DELETE__SUCCESS: 'Xóa tài liệu thành công.',
  BOOK__CREATE__SUCCESS: 'Thêm sách thành công.',
  BOOK__UPDATE__SUCCESS: 'Sửa sách thành công.',
  USER__PASSWORD__EMPTY: 'Vui lòng không để trống mật khẩu',
  USER__PASSWORD__REQUIRED: 'Vui lòng nhập mật khẩu',
  USER__PHONE_NUMBER__UNIQUE: 'Số điện thoại đã tồn tại',
  USER__PHONE_NUMBER__EMPTY: 'Số điện thoại đang để trống',
  USER__EMAIL__REQUIRED: 'Vui lòng nhập Email',
  USER__EMAIL__LENGTH: 'Độ dài Email không được quá 52 ký tự',
  USER__EMAIL__UNIQUE: 'Email đã tồn tại',
  USER__EMAIL__EMPTY: 'Vui lòng không để trống Email',
  USER__TOKEN__EXPIRED_OR_INVALID: 'Token đã hết hạn hoặc không hợp lệ',
  USER__TOKEN__REQUIRED: 'Token là bắt buộc',
  USER__TOKEN__EXPIRED: 'Token đã hết hạn',
  USER__TOKEN__INVALID: 'Token không hợp lệ',
  USER__BIRTHDAY__INVALID_FORMAT: 'Sai định dạng ngày',
  USER__OLD_PASSWORD__EMPTY: 'Vui lòng không để trống nhập lại mật khẩu',
  USER__OLD_PASSWORD__REQUIRED: 'Vui lòng nhập lại mật khẩu',
  USER__NEW_PASSWORD__EMPTY: 'Vui lòng không để trống mật khẩu mới',
  USER__NEW_PASSWORD__REQUIRED: 'Vui lòng nhập mật khẩu mới',
  USER__NEW_PASSWORD_CONFIRM__EMPTY:
    'Vui lòng không để trống trường nhập lại mật khẩu mới',
  USER__NEW_PASSWORD_CONFIRM__REQUIRED: 'Vui lòng nhập lại mật khẩu mới',
  USER__FIELD__UNIQUE: 'Dữ liệu đã tồn tại trong hệ thống',
  ASSETS__NAME__EMPTY: 'Tên tập tin đang để trống',
  ASSETS__NAME__REQUIRED: 'Vui lòng nhập tên tập tin',
  BOOK__NAME__EMPTY: 'Tên sách đang để trống',
  BOOK__NAME__REQUIRED: 'Vui lòng nhập tên sách',
  BOOK__ASSETS_ID__INVALID: 'ID tập tin không đúng',
  BOOK__USER_PROFILE_ID__INVALID: 'ID hồ sơ người dùng không đúng',
  COURSE__NAME__EMPTY: 'Tên khóa học đang để trống',
  COURSE__NAME__REQUIRED: 'Vui lòng nhập tên khóa học',
  COURSE__NAME__UNIQUE: 'Tên khóa học đã tồn tại',
  COURSE__ASSETS_ID__INVALID: 'ID khóa học không tồn tại',
  COURSE__STUDENT_ID__INVALID: 'ID học sinh không tồn tại',
  WORKSPACE__NAME__EMPTY: 'Tên workspace đang để trống',
  WORKSPACE__NAME__REQUIRED: 'Vui lòng tên workspace',
  WORKSPACE__NAME__UNIQUE: 'Tên workspace đã tồn tại',
  WORKSPACE__WORKSPACE__UNIQUE: 'Workspace đã tồn tại',
  WORKSPACE__WORKSPACE__EMPTY: 'Workspace đang để trống',
  WORKSPACE__WORKSPACE__REQUIRED: 'Vui lòng nhập trường workspace',
  WORKSPACE__CREATE__FAILED: 'Tạo Workspace thất bại',
  USER_PROFILE__DOB__INVALID_FORMAT: 'Sai định dạng ngày sinh',
  INVITATION_CODE__BOOK_ID__INVALID: 'Sai mã sách',
  INVITATION_CODE__DELETE__SUCCESS: 'Xóa thành công',
  INVITATION_CODE__CREATE__SUCCESS: 'Tạo thành công',
  STUDENT__UPDATE__SUCCESS: 'Cập nhật thành công',
  STUDENT__CREATE__SUCCESS: 'Tạo thành công',
  USER_PROFILE__UPDATE__SUCCESS: 'Cập nhật thành công',
  WORKSPACE__DELETE__SUCCESS: 'Xóa thành công',
  WORKSPACE__UPDATE__SUCCESS: 'Cập nhật thành công',
  WORKSPACE__CREATE__SUCCESS: 'Tạo thành công',
  COURSE__ADD_STUDENT__SUCCESS: 'Thêm thành công',
  COURSE__ADD_ASSETS__SUCCESS: 'Thêm thành công',
  BOOK__DELETE__SUCCESS: 'Xóa thành công',
  COURSE__UPDATE__SUCCESS: 'Cập nhật thành công',
  COURSE__CREATE__SUCCESS: 'Tạo thành công',
  BOOK__INVITE_USER__SUCCESS: 'Thêm thành công',
  BOOK__ADD_ASSETS__SUCCESS: 'Thêm thành công',
  ASSETS__UPDATE__SUCCESS: 'Cập nhật thành công',
  ASSETS__CREATE__SUCCESS: 'Tạo thành công',
  COURSE__DELETE_SUCCESS: 'Xóa thành công',
  USER_CREATE_SUCCESS: 'Cập nhật email thành công',
  VERIFY_KEY__INPUT__INVALID: 'Mã xác thực không đúng',
  INVITATION_CODE__CODE__EXISTS: 'Mã code đã được sử dụng',
  COURSE__DELETE__SUCCESS: 'Xóa thành công',
  ASSETS__ADD_INVITATION_CODE__SUCCESS: 'Tạo thành công',
  USER__VERIFY_KEY__INVALID: 'Mã xác thực không đúng',
  ASSETS__INVITATION_CODE__INVALID: 'Mã code không đúng.',
  ASSETS__INVITATION_CODE__VERIFIED: 'Xác thực mã code thành công.',
  USERPROFILE__DOB__FORMAT_INVALID: 'Ngày sinh không hợp lệ',
  STAFF__CREATE__SUCCESS: 'Tạo mới nhân viên thành công',
  STAFF__DELETE__SUCCESS: 'Xóa nhân viên thành công',
  ROLE__DELETE__SUCCESS: 'Xóa phân quyền thành công',
  ROLE__CREATE__SUCCESS: 'Tạo mới phân quyền thành công',
  ROLE__DELETE__FAILED: 'Bạn không được xóa phân quyền này',
  STAFF__INVITATION__SUCCESS: 'Gửi lời mời thành công',
  ROLE__UPDATE__SUCCESS: 'Cập nhật phân quyền thành công',
  STAFF_INVITATION__INVITED__BEFORE: 'Nhân viên đã được mời trước đó',
  STAFF__UPDATE__SUCCESS: 'Cập nhật nhân viên thành công',
  SHARE_LIST_ASSETS__CREATE__SUCCESS: 'Tạo thành công',
  SHARE_ASSETS__UPDATE__SUCCESS: 'Cập nhật playlist thành công',
  ASSETS__CODE__VERIFIED: 'Xác thực mã code thành công.',
  PLAYLIST__DELETE__SUCCESS: 'Xóa playlist thành công',
  PLAYLIST__UPDATE__SUCCESS: 'Cập nhật thành công Playlist',
  WORKSPACE__CREATE__NOT_SUPPORT_ON_BETA_VERSION:
    'Tính năng này bị giới hạn ở phiên bản beta',
  BOOK__CREATE_ORDER_ASSETS__SUCCESS: 'Thay đổi vị trí thành công',
  BOOK__UPDATE_ORDER_ASSETS__SUCCESS: 'Thay đổi vị trí thành công',
  COURSE__CREATE_ORDER_ASSETS__SUCCESS: 'Thay đổi vị trí thành công',
  COURSE__UPDATE_ORDER_ASSETS__SUCCESS: 'Thay đổi vị trí thành công',
  COURSES__ADD_STUDENT__SUCCESS: 'Thêm thành công',
  COURSES__DELETE_STUDENT__SUCCESS: 'Xóa thành công',
  COURSES__ADD_ASSETS__SUCCESS: 'Thêm thành công',
  COURSES__DELETE_ASSETS__SUCCESS: 'Xóa thành công',
  BOOK__DELETE_ASSETS__SUCCESS: 'Xóa thành công',
  PLAYLIST__DELETE_ASSETS__SUCCESS: 'Xóa thành công',
  PLAYLIST__ADD_ASSETS__SUCCESS: 'Thêm thành công',
  COURSES_LESSON__DELETE__SUCCESS: 'Xoá buổi học thành công',
  COURSES_LESSON_EXERCISE__ADD__SUCCESS: 'Tạo bài tập thành công',
  COURSES_LESSON_EXERCISE__DELETE__SUCCESS: 'Xoá bài tập thành công',
  COURSES_LESSON__UPDATE__SUCCESS: 'Cập nhật buổi học thành công',
  COURSES_LESSON_EXERCISE__UPDATE__SUCCESS: 'Cập nhật bài tập thành công',
  ASSETS__NAME__UNIQUE: 'Vui lòng nhập tên khác',
  COURSES_LESSON__ADD__SUCCESS: 'Tạo buổi học thành công',
  EXERCISES__UPDATE__SUCCESS: 'Cập nhật bài tập thành công',
  COURSES_LESSON_DOCUMENT__ADD__SUCCESS: 'Thêm tập tin thành công',
  COURSES_LESSON_DOCUMENT__DELETE__SUCCESS: 'Xoá tập tin thành công',
  COURSES_LESSON_DOCUMENT__UPDATE__SUCCESS: 'Cập nhật tập tin thành công',
  ANSWER_EXERCISES__UPDATE__SUCCESS: 'Chấm điểm thành công',
  ATTENDANCE__UPDATE__SUCCESS: 'Cập nhật thông tin điểm danh thành công',
  COURSE_LESSONS__MULTI_CREATE__SUCCESS: 'Tạo buổi học thành công',
  ROLE__UPDATE_PERMISSIONS__SUCCESS: 'Cập nhật phân quyền thành công',
  ROLE__DUPLICATED__SUCCESS: 'Sao chép phân quyền thành công',
  CANT_DELETE_ROLE_HAS_STAFF: 'Xóa phân quyền thất bại',
  PLAYLIST__CREATE__SUCCESS: 'Tạo mới Playlist thành công',
  BOOK__NAME__UNIQUE: 'Vui lòng nhập tên khác cho sách',
  STAFF_INVITATION__EMAIL__INVITED_BEFORE: 'Email này đã được mời',
  USER__IS_ACTIVE__FALSE: 'Người dùng đang hoạt động sai',

  PLAYLIST__CODE__VERIFIED: 'Xác thực thành công',
  JOIN__BOOK__BEFORE: 'Đã tham gia trước đó',
  JOIN__BOOK__SUCCESS: 'Tham gia thành công',
  JOIN__COURSE__FAILURE: 'Tham gia thất bại',
  JOIN__COURSE__BEFORE: 'Đã tham gia trước đó',
  JOIN__COURSE__SUCCESS: 'Tham gia thành công',
  PLAYLIST__UPDATE_ORDINAL__SUCCESS: 'Sắp xếp thành công',
  BOOK__UPDATE_ORDINAL__SUCCESS: 'Sắp xếp thành công',
  COURSE__UPDATE_ORDINAL__SUCCESS: 'Sắp xếp thành công',
  EXAM__UPDATE_ORDINAL__SUCCESS: 'Sắp xếp thành công',
  ASSETS__FOLDER__CAN_NOT_SHARING: 'Không thể chia sẻ thư mục',
  EXAMS__ID__INVALID: 'Id cuộc thì không phù hợp',

  //student
  ACCOUNT__DISABLE__SUCCESS: 'Tài khoản đã bị khóa',
  ACCOUNT__ENABLE__SUCCESS: 'Tài khoản đã được mở khóa',
  ACOUNT__ENABLE__FAILURE: 'Mở khóa tài khoản lỗi',
  ACOUNT__DISABLE__FAILURE: 'Khóa tài khoản lỗi',
  EXAMS__JOIN__BEFORE: 'You have joined before',
  'USER__OLD_PASSWORD__INCORRECT_PASSWORD.': 'Old password is incorrect',

  //user
  CHANGE__PASSWORD__SUCCESS: 'Đổi mật khẩu thành công',
  PERMISSIONGROUPS__DELETE_TEACHER__SUCCESS:
    'Xoá nhân viên khỏi nhóm quyền thành công',
  PERMISSIONGROUPS__NAME__UNIQUE: 'Vui lòng nhập tên khác',
  PERMISSIONGROUPS__UPDATE__SUCCESS: 'Cập nhật nhóm quyền thành công',
  PERMISSIONGROUPS__CREATE__SUCCESS: 'Tạo nhóm quyền thành công',
  PERMISSIONGROUPS__DELETE__SUCCESS: 'Xóa nhóm quyền thành công',

  CLASS__CREATE__SUCCESS: 'Tạo lớp thành công',
  CLASS__UPDATE__SUCCESS: 'Cập nhật lớp thành công',
  CLASS__DELETE__SUCCESS: 'Xóa lớp thành công'
};

export default {
  email: 'Email',
  placeholderEmail: 'Nhập email',
  password: 'Mật khẩu',
  confirmPassword: 'Nhập lại Mật khẩu',
  placeholderPassword: 'Nhập mật khẩu ',
  phoneNumber: 'Số điện thoại',
  placeholderPhone: 'Nhập số điện thoại',
  submit: 'ĐĂNG KÝ',
  haveAccount: 'Bạn đã có tài khoản',
  loginHere: 'Đăng nhập ở đây',
  registerFailed: 'Đăng kí thất bại',
  registerComplete: 'Đăng kí thành công'
};

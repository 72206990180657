export default {
  yourEmail: 'Email đăng nhập',
  plhuseEmail: 'Nhập email',
  pass: 'Mật khẩu',
  plhPass: 'Nhập mật khẩu ',
  remember: 'Ghi nhớ đăng nhập',
  forgotPass: 'Quên mật khẩu?',
  submit: 'ĐĂNG NHẬP',
  errorShow: 'Tài khoản hoặc mật khẩu không đúng, vui lòng thử lại',
  haveAcc: 'Bạn chưa có tài khoản',
  loginHere: 'Đăng kí ở đây',
  loginSucces: 'Đăng nhập thành công',
  confirm: 'Xác nhận',
  numberPhone: 'Số điện thoại',
  plInputNumberPhone: 'Vui lòng nhập số điện thoại',

  wrongPasswordFormat: 'Mật khẩu sai định dạng',
  pleaseInputPass: 'Vui lòng nhập mật khẩu',
  pleaseInputEmail: 'Vui lòng nhập email',
  pleaseInputEmailAgain: 'Vui lòng nhập Email chính xác',
  loginFailed: 'Đăng nhập thất bại',

  pleaseEnterEmail: 'Vui lòng nhập email',
  maxEmail: 'Email không vuợt quá 50 ký tự',
  invalidEmail: 'Email không hợp lệ',

  maxPass: 'Mật khẩu không vuợt quá 64 ký tự',
  minPass: 'Mật khẩu có ít nhất 6 ký tự'
};

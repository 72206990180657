const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  adminLayout: true,
  language: localStorage.getItem('i18nextLng') || 'en'
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,

  reducers: {
    changeAdminLayout: (state, action) => {
      state.adminLayout = action.payload;
    },
    changeLanguageAction: (state, action) => {
      state.language = action.payload;
    }
  }
});

export const { changeAdminLayout, changeLanguageAction } = layoutSlice.actions;
const { reducer: layoutReducer } = layoutSlice;
export default layoutReducer;

export default {
  accuracyPhone: 'Xác thực số điện thoại',
  pleaseIinputEmal:
    'Vui lòng nhập dãy số chúng tôi gửi qua tin nhắn để tiếp tục',
  pleaseOnTime: 'Vui lòng xác minh trong thời gian',
  back: 'Quay lại',
  next: 'Tiếp tục',
  againCode: 'Gửi lại mã',
  emailFalse: 'Email không hợp lệ',
  pleaseInputEmail: 'Làm ơn nhập email',
  inputMember: 'Vui lòng nhập mã nhân viên',
  againPassword: 'Đặt lại mật khẩu',
  notePass:
    'Vui lòng đặt lại mật khẩu mới. Mật khẩu mới bắt buộc khác mật khẩu trước đó',
  inputPass: 'Nhập mật khẩu',
  password: 'Mật khẩu',
  againInputPass: 'Nhập lại mật khẩu',
  enterPass: 'Xác nhận mật khẩu',
  checkSucces: 'Xác nhận mã thành công'
};

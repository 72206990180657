export default {
  grading: 'Monitoring and Grading',
  score: 'Score',
  marked: 'Marked',
  unmarked: 'Unmarked',
  save: 'Save',
  error: 'Error',
  publishScoreSuccess: 'Submitted',

  list: {
    search: 'Enter Contest Name',
    all: 'All',
    submit: 'Submit',
    submitted: 'Submitted',
    level: 'Select Level',
    status: 'Select Status',
    filter: 'Filter',
    done: 'Done',
    undone: 'Undone',
    reset: 'Reset',
    textShow: 'Show',
    view: 'View'
  },

  detail: {
    test: 'Test',
    submit: 'Submit',
    participantList: 'Participant list',
    status: 'Status',
    all: 'All',
    name: 'Name',
    level: 'Level',
    speaking: 'Speaking',
    writing: 'Writing',
    submittedAt: 'Submitted At',
    action: 'Action',
    done: 'Done',
    undone: 'Undone',
    enrolled: 'Enrolled',
    unenroll: 'Unenroll',
    search: 'Enter Student Name',
    selectLevel: 'Select Level',
    selectStatus: 'Select Status',
    chooseTime: 'Choose Time',
    submitted: 'Submitted'
  },
  gradingStudent: {
    listQuestions: 'List questions',
    description: 'Description',
    score: 'Score',
    answer: 'Student’s Answer',
    feedback: 'Teacher’s Feedback',
    scoreIsRequired: 'Please enter score',
    scoreMin0Max9: 'Scores range from 0 to 9',
    invalid: 'Invalid'
  }
};

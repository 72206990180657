export default {
  settings: 'Cài đặt',
  error: 'Lỗi',

  navigation: {
    permissions: 'Quyền',
    permissionsGroup: 'Quyền',
    notifications: 'Thông báo'
  },

  permissions: {
    permissions: 'Quyền',
    settingsManagement: 'Quản lý cài đặt',
    addPermissions: 'Thêm quyền',
    name: 'Tên',
    account: 'Tài khoản',
    action: 'Hành động',
    cannotDeletePermisionGroup: 'Không thể xoá nhóm quyền tồn tại nhân viên'
  },

  notifications: {
    notifications: 'Thông báo',
    textShow: 'Hiển thị'
  },

  modalAddNew: {
    addNewPermissions: 'Thêm mới phân quyền',
    permissions: 'Phân quyền',
    all: 'Tất cả',
    save: 'Lưu'
  },

  modalEdit: {
    name: 'Tên',
    phoneNumber: 'Số điện thoại',
    action: 'Hành động',
    listAccount: 'Danh sách tài khoản',
    findName: 'Tìm tên,số điện thoại'
  },

  confirmDel: {
    deletePermissions: 'Xoá phân quyền',
    delete: 'Bạn có muốn xoá?',
    confirmDelete: 'Xoá'
  }
};

export default {
  accuracyPhone: 'Verify phone number',
  pleaseIinputEmal: 'Please enter the number that we sent via SMS to continue.',
  pleaseOnTime: 'Please verify in',
  back: 'Back',
  next: 'Continue',
  againCode: 'Re-send',
  emailFalse: 'Invalid Email!',
  pleaseInputEmail: 'Please enter Email',
  inputMember: 'Please enter member code',
  againPassword: 'Reset password',
  notePass:
    'Please enter new password. The new password required is different from the previous one.',
  inputPass: 'Enter password',
  password: 'Password',
  againInputPass: 'Re-enter password',
  enterPass: 'Confirm password',
  checkSucces: 'Verified successfully.'
};

export default {
  titleForgotPassword: 'Quên mật khẩu',
  inputEmail: 'Nhập email',
  email: 'Email',
  inputEmailAgain: 'Nhập lại email',
  passwordRetrieval: 'Gởi',
  pleaseInputEmail: 'Vui lòng nhập email',
  pleaseInputEmailAgain: 'Vui lòng nhập Email chính xác',
  invalidEmail: 'Email không hợp lệ',
  emailWordDoNotMatch: 'Email mới không khớp',
  changePassword: 'Thay đổi mật khẩu không thành công',
  emailSentSuccess: 'Email đã được gửi thành công',
  emailSending: 'Gửi email không thành công',

  pleaseEnterEmail: 'Vui lòng nhập email',
  maxEmail: 'Email không vuợt quá 50 ký tự'
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import useTranslation from 'hooks/useTranslation.js';

import { login, setIsRemember, getMyPermission } from 'store/other/authSlice';

import { ROUTES_AUTH, ROUTES_ADMIN, ROUTES_USER } from 'routes/constants';
import { REGEX } from 'utils/constants';
import { ROLE_STUDENT } from 'constants/configs';
import { utilizationContest } from 'utils/constants/enum';
import { getMessagingToken } from 'services/firebase';
import { updateFCMToken } from 'services/apis/admin/notification';

import Input from 'components/Form/InputField';
import Button from 'components/commons/Button';
import { data } from 'components/Layout/data';

import iconUser from 'assets/image/OtherPage/user.svg';
import iconLock from 'assets/image/OtherPage/lock.svg';
import eye from 'assets/image/OtherPage/eye.svg';
import teLogo from 'assets/image/OtherPage/logo-menu-te.png';
import iconShow from 'assets/image/OtherPage/iconShow.svg';
// import vn from 'assets/icons/vn.png';
// import en from 'assets/icons/en.png';

import { LoadingOutlined } from '@ant-design/icons';

import * as S from './styled';

function LoginPage() {
  const { t } = useTranslation('loginPage');
  const [formLogin] = Form.useForm();

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const errLogin = useSelector((state) => state.authReducer.error);
  const loading = useSelector((state) => state.authReducer.loading);
  // const { language } = useSelector(state => state.layoutReducer);

  const [loadingLogin, setLoadingLogin] = useState(false);
  const history = useHistory();

  const handleLogin = async (values) => {
    setLoadingLogin(true);

    if (rememberMe) {
      dispatch(setIsRemember(rememberMe));
    }

    try {
      const res = await dispatch(login({ ...values }));
      if (res?.payload?.tokens?.access) {
        getMessagingToken()
          .then((token) => {
            updateFCMToken(res?.payload?.id, {
              fcm_token: token
            }).catch(() => {
              // console.log('You are super admin - fcm is not available');
            });
          })
          .catch(() => {
            return;
          });

        if (res?.payload?.role === ROLE_STUDENT) {
          history.replace(
            generatePath(ROUTES_USER.CONTEST, {
              utilization: utilizationContest.test
            })
          ); // TODO homepage
        } else {
          const resPermission = await dispatch(getMyPermission());
          const findShouldRouterPath = data?.find(
            (item) =>
              resPermission?.payload?.data?.permission?.slider_bar[
                item?.permissionSidebarKey
              ]
          )?.link;
          history.replace(findShouldRouterPath || '/admin/settings'); // TODO homepage
        }
      }
    } catch (error) {
      setLoadingLogin(false);
    }
    setLoadingLogin(false);
  };

  return (
    <S.Wrapper>
      {/* <S.LangWrap>
        <button
          type="button"
          className={language === 'vi' ? 'active' : ''}
          onClick={() => {
            i18n.changeLanguage('vi');
            dispatch(changeLanguageAction('vi'));
          }}
        >
          <img src={vn} alt="" />
        </button>
        <button
          type="button"
          className={language === 'en' ? 'active' : ''}
          onClick={() => {
            i18n.changeLanguage('en');
            dispatch(changeLanguageAction('en'));
          }}
        >
          <img src={en} alt="" />
        </button>
      </S.LangWrap> */}
      <S.BackgroundImage />
      <S.Header>
        <S.Logo src={teLogo} />
      </S.Header>
      <S.WrapperForm>
        <S.Title>{t('submit')}</S.Title>
        <Form onFinish={handleLogin} form={formLogin} layout="vertical">
          <Form.Item
            label={t('email')}
            name="email"
            required
            rules={[
              {
                validator: async (_, email) => {
                  if (!email?.length) {
                    return Promise.reject(new Error(t('pleaseEnterEmail')));
                  } else if (email?.length > 50) {
                    return Promise.reject(new Error(t('maxEmail')));
                  } else if (!REGEX?.EMAIL.test(email)) {
                    return Promise.reject(new Error(t('invalidEmail')));
                  }
                }
              }
            ]}
          >
            <Input placeholder={t('plhuseEmail')} icon={iconUser} />
          </Form.Item>
          <Form.Item
            label={t('pass')}
            name="password"
            rules={[
              {
                required: true,
                message: t('pleaseInputPass')
              },
              {
                validator: async (_, pass) => {
                  if (pass?.length > 64) {
                    return Promise.reject(new Error(t('maxPass')));
                  } else if (pass?.length < 6) {
                    return Promise.reject(new Error(t('minPass')));
                  }
                }
              }
            ]}
          >
            <Input
              icon={iconLock}
              iconPass={show ? iconShow : eye}
              placeholder={t('plhPass')}
              type={show ? 'text' : 'password'}
              set_show={setShow}
              show={show}
            />
          </Form.Item>
          <S.ForgotPass>
            <S.BoxRadio>
              <S.RadioCustom checked={rememberMe} setChecked={setRememberMe} />
              <a>{t('remember')}</a>
            </S.BoxRadio>
            <S.ForgotPassWord
              onClick={() => history.push(ROUTES_AUTH?.FORGOT_PASS_PAGE)}
            >
              {t('forgotPass')}
            </S.ForgotPassWord>
          </S.ForgotPass>
          {errLogin?.length && !loading && (
            <S.ErrorMessage>
              <span>{t('errorShow')}</span>
            </S.ErrorMessage>
          )}
          <Button
            onClick={() => formLogin.submit()}
            className="btn-login"
            type="submit"
            width="100%"
            disabled={loadingLogin}
          >
            {loadingLogin ? (
              <>
                <S.LoadingWrapper>
                  <LoadingOutlined />
                </S.LoadingWrapper>
                {t('submit')}
              </>
            ) : (
              <>{t('submit')}</>
            )}
          </Button>
        </Form>
      </S.WrapperForm>
    </S.Wrapper>
  );
}

export default LoginPage;

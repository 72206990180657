const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  validateForm: false,
  resetData: false,
  resultValidate: false,
  listKeyDeleteBlock: [],
  listKeyDeleteOptions: [],
  verifyTwitterUsername: false
};

const questionSlice = createSlice({
  name: 'question',
  initialState,

  reducers: {
    actionValidateFormChildren: (state, action) => {
      state.validateForm = action.payload.data;
    },
    actionSetResultValidateFormChildren: (state, action) => {
      state.resultValidate = action.payload.data;
    },
    actionUpdateListKeyDeleteBlock: (state, action) => {
      state.listKeyDeleteBlock = action.payload.data;
    },
    actionUpdateListKeyDeleteOptions: (state, action) => {
      state.listKeyDeleteOptions = action.payload.data;
    },

    actionResetData: state => {
      state.resetData = !state.resetData;
    },

    actionVerifyTwitterUsername: (state, action) => {
      state.verifyTwitterUsername = action.payload?.status;
    }
  },

  extraReducers: {}
});

export const {
  actionValidateFormChildren,
  actionSetResultValidateFormChildren,
  actionUpdateListKeyDeleteBlock,
  actionUpdateListKeyDeleteOptions,
  actionResetData,
  actionVerifyTwitterUsername
} = questionSlice.actions;
const { reducer: questionReducer } = questionSlice;
export default questionReducer;

export default {
  accountSettings: 'Cài đặt tài khoản',

  navigation: {
    profileSettings: 'Cài đặt hồ sơ',
    historyContest: 'Lịch sử kỳ thi',
    changePassword: 'Thay đổi mật khẩu',
    notifications: 'Thông báo'
  },

  profileSettings: {
    profileSettings: 'Cài đặt hồ sơ',
    basicInformation: 'Thông tin cơ bản',
    name: 'Tên',
    phoneNumber: 'Số điện thoại',
    email: 'Email',
    class: 'Lớp',
    entryScore: 'Điểm đầu vào',
    reading: 'Đọc',
    listening: 'Nghe',
    speaking: 'Nói',
    writing: 'Viết',
    cancel: 'Hủy',
    saveChanges: 'Lưu thay đổi',
    noInformationChange: 'Không có thông tin nào thay đổi'
  },

  historyContest: {
    historyContest: 'Lịch sử kỳ thi',
    name: 'Tên',
    listening: 'Nghe',
    reading: 'Nói',
    speaking: 'Đọc',
    writing: 'Viết',
    overall: 'Tổng quát',
    status: 'Trạng thái',
    time: 'Thời gian',
    submittedAt: 'Thời gian nộp',
    action: 'Hành động',
    enrolled: 'Đã đăng ký',
    unenroll: 'Hủy đăng ký'
  },

  changePassword: {
    changePassword: 'Thay đổi mật khẩu',
    oldPassword: 'Mật khẩu cũ',
    newPassword: 'Mật khẩu mới',
    confirmNewPassword: 'Nhập lại mật khẩu mới',
    enterOldPass: 'Nhập mật khẩu cũ',
    enterNewPass: 'Nhập mạt khẩu mới',
    confirmNewPass: 'Xác nhận lại mật khẩu mới',
    pleaseInputOldPass: 'Vui lòng nhập lại mật khẩu cũ',
    maxPass: 'Mật khẩu không vuợt quá 64 ký tự',
    minPass: 'Mật khẩu có ít nhất 6 ký tự',
    pleaseInputPass: 'Vui lòng nhập mật khẩu',
    wrongPasswordFormatMin: 'Mật khẩu có ít nhất 6 ký tự',
    wrongPasswordFormatMax: 'Mật khẩu không vuợt quá 20 ký tự',
    changePasswordFalied: 'Thay đổi mật khẩu không thành công',
    changePasswordSuccess: 'Thay đổi mật khẩu thành công',
    passWordDoNotMatch: 'Mật khẩu mới không khớp',
    invalidPass: 'Mật khẩu không có khoảng trắng'
  },

  notifications: {
    notifications: 'Thông báo',
    textShow: 'Hiển thị'
  }
};

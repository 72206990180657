export default {
  contest: 'Quản lý bộ đề thi',
  findQuestion: 'Tìm bộ đề thi',
  test: 'Bài Kiểm tra',
  practice: 'Luyện tập',

  list: {
    name: 'Tên',
    addNew: 'Thêm mới',
    action: 'Hành động',
    deleteContest: 'Xoá bộ đề thi',
    delete: 'Xoá',
    confirmDelete: 'Bạn muốn xoá bộ đề thi này',
    deleteAllContest: 'Xoá toàn bộ đề thi',
    deleteAll: 'Xoá toàn bộ',
    confirmDeleteAll: 'Bạn muốn xoá toàn bộ đề thi',
    filter: 'Bộ lọc',
    pleaseEnterContest: 'Vui lòng nhập tên bộ đề thi',
    createdAt: 'Ngày tạo',
    createdBy: 'Được tạo mởi',
    level: 'Trình độ',
    timeStart: 'Thời gian tạo',
    timeEnd: 'Thời gian kết thúc',
    type: 'Kiểu',
    status: 'Trạng thái',
    reset: 'Reset',
    selectLevel: 'Chọn level',
    selectStatus: 'Chọn Trạng Thái',
    selectType: 'Chọn Kiểu'
  },

  create: {
    contest: 'Bộ đề thi',
    createContest: 'Tạo bộ đề thi',
    createPractice: 'Tạo luyện tập',
    name: 'Tên bài thi',
    enterName: 'Nhập tên bộ đề thi',
    enterNamePractice: 'Nhập tên bài luyện tập',
    participants: 'Những người tham gia',
    missingName: 'Vui lòng nhập tên bài thi',
    missingLevel: 'Vui lòng chọn trình độ',
    errorName: 'Tên có tối đa 256 ký tự',
    description: 'Mô tả',
    enterDesciption: 'Nhập Mô Tả',
    listSelectedQuestions: 'Liệt kê các câu hỏi đã chọn',
    nameQuestion: 'Tên câu hỏi',
    missingQuestion: 'Vui lòng chọn câu hỏi',
    level: 'Trình độ',
    selectLevel: 'Chọn trình độ',
    skill: 'Kỹ năng',
    time: 'Thời gian(s)',
    actions: 'Hành động',
    addQuestion: 'Thêm câu hỏi',
    deleteQuestion: 'Xoá câu hỏi',
    doYouWant: 'Bạn có muốn xoá câu hỏi này?',
    startAtCannot: 'Ngày bắt đầu không được nhỏ hơn hôm nay',

    beginner: 'Vỡ lòng',
    intermediate: 'Trung cấp',
    advanced: 'Cao cấp',

    type: 'Loại',
    timeStart: 'Thời Gian Bắt Đầu',
    timeEnd: 'Thời Gian Kết Thúc',
    draftContest: 'Lưu bản nháp',
    listSelectedStudent: 'Danh Sách Học Sinh Đã Chọn',
    addStudent: 'Thêm học sinh',
    nameStudent: 'Tên học sinh',
    phoneNumber: 'Số điện thoại',
    uploadImage: 'Tải lên hình ảnh',
    maxImage: 'Tối đa 1 hình ảnh định dạng , jpg, png',
    pleaseAdd: 'Vui lòng nhập thời gian',

    startAtFailed: 'Ngày bắt đầu phải nhỏ hơn ngày kết thúc',
    endAtFailed: 'Ngày kết thúc lớn hơn ngày bắt đầu',

    erroMess: 'Vui lòng chọn câu hỏi',
    updatePractice: 'Cập nhật luyện tập',
    updateContest: 'Cập nhật bộ đề thi'
  },

  options: {
    course1: 'Khoá học 1',
    course2: 'Khoá học 2',
    course3: 'Khoá học 3'
  },

  modalAddNew: {
    name: 'Tên câu hỏi',
    level: 'Trình độ',
    skill: 'Kỹ năng',
    time: 'Thời gian(s)',
    action: 'Hành động',
    add: 'THÊM',
    done: 'Xong',
    addListening: 'Thêm bộ câu hỏi nghe',
    addWriting: 'Thêm bộ câu hỏi viết',
    addReading: 'Thêm bộ câu hỏi đọc',
    addSpeaking: 'Thêm bộ câu hỏi nói',
    listening: 'Nghe',
    reading: 'Đọc',
    writing: 'Viết',
    speaking: 'Nói',
    findQuestion: 'Tên câu hỏi',
    beginner: 'Sơ cấp',
    intermediate: 'Trung cấp',
    advanced: 'Cao cấp',

    addStudent: 'Thêm học sinh',
    findStudent: 'Tìm học sinh',
    nameStudent: 'Tên học sinh',
    phoneNumber: 'Số điện thoại',

    cancel: 'Huỷ'
  },

  update: {
    updateContest: 'Cập nhật bộ câu hỏi',
    update: 'Cập nhật',
    updatePractice: 'Cập nhật bài luyện tập'
  },

  type: {
    private: 'Riêng tư',
    public: 'Công khai'
  },

  status: {
    ended: 'Đã kết thúc',
    upcoming: 'Sắp tới',
    ongoing: 'Đang diễn ra',
    draft: 'Dự thảo',

    contest: 'Bài thi',
    practice: 'Luyện tập'
  },

  student: {
    deleteStudent: 'Xoá học sinh',
    doYouWant: 'Bạn muốn xoá học sinh này?'
  }
};

export default {
  resetPassword: 'Đổi mật khẩu',
  newPassword: 'Mật khẩu mới',
  inputNewPassword: 'Nhập lại mật khẩu mới',
  update: 'Cập nhật',
  pleaseInputPass: 'Vui lòng nhập mật khẩu',
  wrongPasswordFormatMin: 'Mật khẩu có ít nhất 6 ký tự',
  wrongPasswordFormatMax: 'Mật khẩu không vuợt quá 20 ký tự',
  changePasswordFalied: 'Thay đổi mật khẩu không thành công',
  changePasswordSuccess: 'Thay đổi mật khẩu thành công',
  passWordDoNotMatch: 'Mật khẩu mới không khớp',
  invalidPass: 'Mật khẩu không có khoảng trắng'
};

import request from 'services/request';

export const getListLevelNoPermissionApi = async params =>
  request({
    url: '/admin/level/list-no-permissions',
    method: 'GET',
    params
  });

export const getListPositionNoPermissionApi = async params =>
  request({
    url: '/admin/position/list-no-permissions',
    method: 'GET',
    params
  });

export const getListSkillNoPermissionApi = async params =>
  request({
    url: '/admin/skills/list-no-permissions',
    method: 'GET',
    params
  });

export const getListUserNoPermissionApi = async params =>
  request({
    url: '/admin/users/list-user-no-permissions',
    method: 'GET',
    params
  });

export const getListCourseCatalogNoPermissionApi = async params =>
  request({
    url: '/admin/categories/list-no-permissions',
    method: 'GET',
    params
  });

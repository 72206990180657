import React, { useEffect, useState } from 'react';

import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'antd/dist/antd.min.css';
import { I18nextProvider } from 'react-i18next';
import i18n from 'services/i18n';
import Routes from './routes';
import GlobalStyle from './styles/globalStyle';
import theme from './styles/theme';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './store';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/css/ckeditor.css';

import NotificationCommon from 'components/Notification';
import { onMessageListener } from 'services/firebase';

function App() {
  const [dataNotifi, setDataNotifi] = useState(null);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/firebase-messaging-sw.js');
      });
    }
  }, []);

  useEffect(() => {
    if (window.Notification && Notification.permission !== 'denied') {
      Notification.requestPermission();
    }
  }, []);

  onMessageListener()
    .then((payload) => {
      setDataNotifi(payload);
    })
    .catch(() => {
      //
    });

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Routes />
          <GlobalStyle />
          <ToastContainer
            position="top-center"
            hideProgressBar
            autoClose={5000}
          />
          <NotificationCommon payload={dataNotifi} />
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  );
}

export default App;

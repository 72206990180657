import { toast } from 'react-toastify';

import ToastMessage from 'components/Toast';

export const messageForbiden = () =>
  toast(
    <ToastMessage
      type="error"
      message={['youDontHavePermission']}
      translation="layout"
    />
  );

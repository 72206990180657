export default {
  titleForgotPassword: 'Forgot password',
  inputEmail: 'Enter Email',
  email: 'Email',
  inputEmailAgain: 'Re-enter Email',
  passwordRetrieval: 'Send',
  pleaseInputEmail: 'Please enter Email',
  pleaseInputEmailAgain: 'Please enter a correct Email.',
  invalidEmail: 'Invalid Email.',
  emailWordDoNotMatch: 'New email do not match.',
  changePassword: 'Password change failed',
  emailSentSuccess: 'Email has been sent successfully',
  emailSending: 'Email sending failed',

  pleaseEnterEmail: 'Please enter your email',
  maxEmail: 'Email is not exceeded 50 characters'
};

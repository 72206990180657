export const REGEX = {
  PHONE: /\b0\d+$|84\d+$|[+]84\d+$\b/,
  PART_CODE: /^[a-z0-9]*$/,
  PASS: /^\S+$/,
  URL: /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  INT_POSITIVE: /^[1-9]\d*$/,
  // eslint-disable-next-line no-useless-escape
  EMAIL: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
  UPCASE_CHAR: /^[A-Z]{0,1}$/,
  SCORE: /^(9(\.0)?|[0-8](\.\d)?)(,\d?)?$/
};

export const fileLengthLimit = 20;

export const sizeOptions = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 }
];

export const sizeOptionsListContest = [
  { label: 8, value: 8 },
  { label: 12, value: 12 },
  { label: 16, value: 16 },
  { label: 32, value: 32 },
  { label: 64, value: 64 }
];

export const statusTestOptions = [
  { label: 'Tất cả', value: '' },
  { label: 'Đã chấm', value: 1 },
  { label: 'Chưa chấm', value: 0 }
];

export const dateFormat = 'DD/MM/YYYY';
export const timeFormat = 'HH:mm:ss';
export const fullDateFormat = 'DD/MM/YYYY hh:mm:ss';

export const fileTypeAccept = [
  'image/gif', // .gif
  'image/png', // .png
  'image/jpeg', // .jpeg, .jpg
  'audio/mpeg', // .mpga
  'audio/wav', // .wav
  'video/mp4', // .mp4
  'video/x-m4v', // .m4v
  'video/x-flv', // .flv
  'video/mpeg', // .mpeg
  'video/x-msvideo', // .avi
  'application/vnd.ms-excel', // .xls
  'application/msword', // .doc
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/pdf' // .pdf
];

export const OPTIONS_QUESTIONS = '_options_questions';
export const DATA_QUESTIONS = '_data_questions';
export const OPTIONS_QUESTIONS_UPDATE = '_options_questions_update';
export const DATA_QUESTIONS_UPDATE = '_data_questions_update';

export const LIST_STUDENTS_CHECKED = '_list_students_checked';
export const LIST_STUDENTS_RENDER_UI_TABLE = '_list_students_render_ui_table';

export const SPEAKING_SUBMIT = '_speaking_submit';
export const DATA_SUBMIT = '_data_submit';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  examSubmit: {},
  writingSubmit: []
};

const examSlice = createSlice({
  name: 'exam',
  initialState,

  reducers: {
    examSubmitAction: (state, action) => {
      state.examSubmit = action.payload.data;
    },

    writingSubmitAction: (state, action) => {
      state.writingSubmit = action.payload.data;
    }
  }
});

export const { examSubmitAction, writingSubmitAction } = examSlice.actions;
const { reducer: examReducer } = examSlice;
export default examReducer;

export default {
  name: 'Tên',
  action: 'Thao tác',
  level: 'Cấp',
  skill: 'Kỹ năng',
  type: 'Loại',
  delete: 'Xóa',
  confirmDelete: 'Xác nhận xóa',
  update: 'Cập nhật',
  save: 'Lưu',
  invalid: 'Không hợp lệ',
  createQuestion: 'Tạo câu hỏi',
  question: 'Quản lý câu hỏi',
  saved: 'Đã lưu',

  list: {
    listQuestion: 'Danh sách câu hỏi',
    displayAll: 'Hiển thị toàn bộ câu hỏi',
    createNew: 'Tạo mới',
    name: 'Tên',
    level: 'Trình độ',
    beginner: 'Sơ cấp',
    intermediate: 'Trung cấp',
    advanced: 'Cao cấp',
    skill: 'Kỹ năng',
    listening: 'Nghe',
    writing: 'Viết',
    reading: 'Đọc',
    speaking: 'Nói',
    time: 'Thời gian(s)',
    action: 'Hành động',
    deleteQuestion: 'Xoá câu hỏi',
    deleteAllQuestion: 'Xoá toàn bộ câu hỏi',
    confirmDeleteAll: 'Bạn muốn xoá toàn bộ câu hỏi',
    confirmDelete: 'Bạn muốn xoá câu hỏi này',
    pleaseEnterAQuestion: 'Nhập tên câu hỏi',
    deleteAll: 'Xoá tất cả',
    questionManageMent: 'Quản lý câu hỏi',
    filter: 'Bộ lọc',
    reset: 'Đặt lại'
  },

  create: {
    name: 'Tên',
    enterDescription: 'Nhập mô tả',
    nameOfQuestion: 'Tên câu hỏi',
    level: 'Trình độ',
    skill: 'Kỹ năng',
    description: 'Mô tả',
    uploadFile: 'Tải lên tài liệu',
    missingName: 'Vui lòng nhập tên',
    missingTimeLimit: 'Vui lòng nhập thời gian',
    vocabularies: 'Từ vựng',
    levelOfQuestion: 'Cấp của câu hỏi',
    enterVocabularies: 'Nhập từ vựng',
    createQuestion: 'Tạo câu hỏi',
    timeLimit: 'Giới hạn thời gian (Đơn vị phút)',
    placeHolderTimeLimit: 'Giới hạn thời gian',
    missingLevel: 'Vui lòng chọn trình độ'
  },

  options: {
    beginner: 'Người mới',
    advanced: 'Nâng cao',
    intermediate: 'Trung cấp',
    listening: 'Nghe',
    reading: 'Đọc',
    writing: 'Viết',
    speaking: 'Nói',
    multipleChoice: 'Nhiều đáp án đúng',
    singleChoice: 'Một đáp án đúng',
    true: 'Đúng',
    false: 'Sai',
    ng: 'Không xác định',
    yes: 'Có',
    no: 'Không'
  },

  matching: {
    mustMoreAnswer: 'Phải không ít hơn số lượng câu trả lời',
    enterDescription: 'Nhập mô tả',
    missingDescription: 'Thiếu mô tả',
    missingTitleListHeading: 'Thiếu tiêu đề danh sách tiêu đề',
    titleListHeading: 'Tiêu đề danh sách tiêu đề',
    listHeading: 'Danh sách tiêu đề',
    titleQA: 'Tiêu đề Q&A',
    atLeast1Heading: 'Ít nhất 1 tiêu đề',
    missingHeading: 'Thiếu tiêu đề',
    enterYourHeading: 'Câu trả lời',
    addHeading: 'Thêm tiêu đề',
    missingTitleListAnswer: 'Thiếu tên danh sách câu trả lời',
    titleListAnswer: 'Tên danh sách câu trả lời',
    listAnswer: 'Q&A',
    atLeast1Answer: 'Ít nhất 1 câu trả lời',
    missingIndex: 'No',
    index: 'Câu hỏi',
    label: 'Nhãn',
    answer: 'Câu trả lời',
    missingAnswer: 'Thiếu câu trả lời',
    enterYourAnswer: 'Câu trả lời',
    addAnswer: 'Thêm câu trả lời',
    selectHeading: 'Chọn tiêu đề đúng',
    placeHolderListHeading: 'Tiêu đề danh sách tiêu đề',
    placeHolderTitleQA: 'Tiêu đề Q&A',
    placeHolderQuestion: 'Số.'
  },

  multipleChoice: {
    enterDescription: 'Nhập nội dung mô tả bộ câu hỏi',
    missingDescription: 'Thiếu mô tả',
    atLeast2Answer: 'Ít nhất 2 đáp án',
    missingIndex: 'No',
    missingAnswer: 'Thiếu đáp án',
    enterYourAnswer: 'Câu trả lời',
    addAnswer: 'Thêm đáp án',
    description: 'Mô tả',
    answer: 'Đáp án'
  },

  rearrange: {
    question: 'Câu hỏi',
    describle: 'Mô tả câu hỏi của bạn',
    theOnlyThing:
      'Điều duy nhất bạn sẽ phải lo lắng là làm thế nào để giải thích điều đó với bọn trẻ.',
    haveTo:
      'có /to/ điều/ làm thế nào /to/ giải thích/ nó/ cho/bạn sẽ /không bao giờ/ những /trẻ em/ lo lắng/ về /là/ The/ duy nhất/',
    pleaseEnterDescription: 'Vui lòng nhập mô tả của bạn',
    pleaseEnterQuestion: 'Vui lòng nhập câu hỏi của bạn',
    questionIsNot: 'Câu hỏi không được vượt quá 255 ký tự',
    save: 'Lưu'
  },

  writeAFullEssay: {
    save: 'Lưu',
    enterADescription: 'Mô tả',
    pleaseEnterADescription: 'Vui lòng nhập nội dung mô tả bộ câu hỏi',
    question: 'Mô tả'
  },

  fillInGaps: {
    description: 'Mô tả',
    question: 'Câu hỏi',
    answer: 'Câu trả lời',
    save: 'Lưu',
    pleaseYourDesc: 'Vui lòng nhập mô tả của bạn',
    pleaseYourAnswer: 'Vui lòng nhập câu trả lời của bạn',
    placeHolderDesc: 'Nhập mô tả [1]......[2].....',
    placeHolderAnswer: 'Nhập câu trả lời',
    mustQuestion: 'Phải có ít nhất 1 câu hỏi'
  },

  mapLabelling: {
    description: 'Mô tả',
    question: 'Câu hỏi',
    answer: 'Câu trả lời',
    save: 'Lưu',
    pleaseYourDesc: 'Vui lòng nhập mô tả của bạn',
    positive: 'Nhập số nguyên dương',
    pleaseYourQuestion: 'Vui lòng nhập câu hỏi',
    placeHolderQuestion: 'Số.',
    pleaseYourAnswer: 'Vui lòng nhập câu trả lời của bạn',
    placeHolderDesc: 'Nhập nội dung mô tả bộ câu hỏi',
    placeHolderAnswer: 'Nhập câu trả lời',
    add: 'Thêm câu trả lời'
  },

  trueFalse: {
    description: 'Mô tả',
    question: 'Câu hỏi',
    answer: 'Câu trả lời',
    save: 'Lưu',
    pleaseYourDesc: 'Vui lòng nhập mô tả của bạn',
    positive: 'Nhập số nguyên dương',
    pleaseYourQuestion: 'Vui lòng nhập câu trả lời',
    placeHolderAnswer: 'Chọn',
    pleaseYourAnswer: 'Vui lòng chọn câu trả lời',
    placeHolderDesc: 'Nhập nội dung mô tả bộ câu hỏi',
    add: 'Thêm câu trả lời',
    pleaseYourIndex: 'Nhập câu hỏi',
    placeHolderQuestion: 'Nội dung',
    placeHolderIndex: 'Số.'
  },

  yesNo: {
    description: 'Mô tả',
    question: 'Câu hỏi',
    answer: 'Câu trả lời',
    save: 'Lưu',
    pleaseYourDesc: 'Vui lòng nhập mô tả của bạn',
    positive: 'Nhập số nguyên dương',
    pleaseYourQuestion: 'Vui lòng nhập câu trả lời',
    placeHolderAnswer: 'Chọn',
    pleaseYourAnswer: 'Vui lòng chọn câu trả lời',
    placeHolderDesc: 'Nhập nội dung mô tả bộ câu hỏi',
    add: 'Thêm câu trả lời',
    pleaseYourIndex: 'Nhập câu hỏi',
    placeHolderQuestion: 'Nội dung',
    placeHolderIndex: 'Số.'
  }
};

export default {
  grading: 'Giám sát và chấm điểm',

  list: {
    search: 'Tìm kiếm',
    all: 'Tất cả',
    submit: 'Gửi',
    submitted: 'Đã gửi',
    level: 'Chọn trình độ',
    status: 'Chọn trạng thái',
    filter: 'Lọc',
    done: 'Hoàn thành',
    undone: 'Chưa hoàn thành',
    reset: 'Đặt lại'
  },

  detail: {
    test: 'Kiểm tra',
    submit: 'Gửi',
    participantList: 'Danh sách người tham gia',
    status: 'Trạng thái',
    all: 'Tất cả',
    name: 'Tên',
    level: 'Trình độ',
    speaking: 'Nói',
    writing: 'Viết',
    submittedAt: 'Thời điểm gửi',
    action: 'Hành động',
    done: 'Hoàn thành',
    undone: 'Chưa hoàn thành',
    enrolled: 'Đã đăng ký',
    unenroll: 'Chưa đăng ký',
    search: 'Tìm học sinh',
    selectLevel: 'Chọn trình độ',
    selectStatus: 'Chọn trạng thái',
    chooseTime: 'Chọn thời gian'
  }
};

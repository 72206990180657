import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge } from 'antd';

import { ROUTES_ADMIN } from 'routes/constants';
import Image from '../Image';

import notificationIcon from 'assets/icons/notification.svg';

import * as S from './Notification';

export default function Notification() {
  const history = useHistory();

  const { unreadNotificationsCount } = useSelector(
    (state) => state.notificationReducer
  );

  return (
    <S.Wrapper
      onClick={() => history.push(`${ROUTES_ADMIN.SETTINGS}?tab=notifications`)}
    >
      <Badge
        size="small"
        count={unreadNotificationsCount > 9 ? '9+' : unreadNotificationsCount}
      >
        <Image src={notificationIcon} alt="" />
      </Badge>
    </S.Wrapper>
  );
}

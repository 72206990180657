export default {
  student: 'Student',
  no: 'No',
  name: 'Name',
  class: 'Class',
  email: 'Email',
  phone: 'Phone',
  action: 'Action',
  addNew: 'Add new',
  edit: 'Edit Student',
  find: 'Enter Student Name / Email / Phone',
  active: 'Active',
  unknownError: 'Unknown error',
  USER__REGISTER__SUCCESS: 'Student added successfully',
  level: 'Level',
  advanced: 'Advanced',
  intermediate: 'Intermediate',
  beginner: 'Beginner',
  list: 'List Student',
  leaderBoard: 'Leaderboard',
  numExamCompleted: 'Number Exam Completed',
  sumOverall: 'Sum Overall',
  averageOverall: 'Average Overall',
  enterStudent: "Enter student's name",
  search: 'Enter Student Name',
  class: 'Class',
  reset: 'Reset',
  sort: 'Sort By',
  timeStart: 'Time Start',
  timeEnd: 'Time End',
  detailLeaderBoard: 'Detail Leaderboard',

  modalAddNew: {
    addNew: 'Add new student',
    add: 'add new',
    edit: 'save',
    basic: 'Basic information',
    name: 'Name',
    placeHolderName: 'Enter student name',
    pleaseEnterName: 'Please enter student name',
    nameIsNot: 'Student name is not exceeded 256 characters',
    class: 'Class',
    placeHolderClass: 'Enter Class',
    email: 'Email',
    placeHolderEmail: 'Enter Email',
    pleaseEnterEmail: 'Please enter Email',
    emailIsNot: 'Email is not exceeded 256 characters',
    emailInvalid: 'Invalid Email',
    emailExits: 'Email already exists',
    phone: 'Phone number',
    placeHolderPhone: 'Enter phone number',
    pleaseEnterPhone: 'Please enter phone number',
    phoneIsNot: 'Invalid phone number',
    avatar: 'Avatar',
    entraceScroe: 'Entrance score',
    reading: 'Reading',
    placeHolderReading: 'Reading Score',
    listening: 'Listening',
    placeHolderListening: 'Listening Score',
    writing: 'Writing',
    placeHolderWriting: 'Writing Score',
    speaking: 'Speaking',
    placeHolderSpeaking: 'Speaking Score',
    note: '  **Default password: tes@2023',
    uploadImage: 'Upload image',
    required: 'Max 1 image, jpg, png format',
    invalidEmail: 'Invalid email',
    invalidScore: 'Invalid score',
    level: 'Level',
    missingLevel: 'Missing level',
    cancel: 'Cancel'
  },

  overView: {
    studentProfile: 'Student Profile',
    historyContest: 'History Contest',
    listening: 'Listening',
    speaking: 'Speaking',
    reading: 'Reading',
    writing: 'Writing',
    name: 'Name',
    type: 'Type',
    overall: 'Overall',
    status: 'Status',
    time: 'Time',
    submittedAd: 'Submitted At',
    enrolled: 'Enrolled',
    unenroll: 'Unenroll',
    action: 'Action'
  }
};

export default {
  contest: 'Exmams Management',
  test: 'Test',
  practice: 'Practice',
  examManage: 'Contest',

  list: {
    name: 'Name',
    addNew: 'Add new',
    action: 'Actions',
    deleteContest: 'Delete contest',
    delete: 'Delete',
    confirmDelete: 'Do you want delete this contest',
    deleteAllContest: 'Delete all contest',
    deleteAll: 'Delete',
    confirmDeleteAll: 'Do you want to delete the selected contests?',
    filter: 'Filter',
    pleaseEnterContest: 'Enter Contest Name',
    pleaseEnterPractice: 'Enter Practice Name',
    createdAt: 'Created At',
    createdBy: 'Created By',
    level: 'Level',
    timeStart: 'Time start',
    timeEnd: 'Time end',
    type: 'Type',
    status: 'Status',
    reset: 'Reset',
    selectLevel: 'Select Level',
    selectStatus: 'Select Status',
    selectType: 'Select Type',
    class: 'Class',
    selectClass: 'Select Class'
  },

  create: {
    contest: 'contest',
    createContest: 'Create contest',
    createPractice: 'Create practice',
    name: 'Name',
    enterName: 'Enter contest name',
    enterNamePractice: 'Enter practice name',
    participants: 'Participants',
    missingName: 'Please enter name of contest',
    missingLevel: 'Please select level',
    errorName: 'Name is not exceeded 256 characters',
    description: 'Description',
    enterDesciption: 'Enter Description',
    listSelectedQuestions: 'List Selected Questions',
    nameQuestion: 'Question Name',
    missingQuestion: 'Please select question',
    level: 'Level',
    selectLevel: 'Select Level',
    skill: 'Skill',
    time: 'Time(s)',
    actions: 'Actions',
    addQuestion: 'Add question',
    deleteContest: 'Delete contest',
    doYouWant: 'Do you want delete this question?',
    deleteQuestion: 'Delete Question',
    startAtCannot: 'Start date cannot be less than today',

    speaking: 'Speaking',
    reading: 'Reading',
    writing: 'Writing',
    listening: 'Listening',

    beginner: 'Beginner',
    intermediate: 'Intermediate',
    advanced: 'Advanced',

    type: 'Type',
    timeStart: 'Time Start',
    timeEnd: 'Time End',
    draftContest: 'Save draft',
    listSelectedStudent: 'Student List',
    addStudent: 'Add Student',
    nameStudent: 'Student name',
    phoneNumber: 'Phone number',
    uploadImage: 'Upload image',
    maxImage: 'Max 1 image, jpg, png format',
    pleaseAdd: 'Please enter date of contest',

    startAtFailed: 'Start date must be less than end date',
    endAtFailed: 'The end date is greater than the start date',

    erroMess: 'Please choose question',
    updatePractice: 'Update practice',
    updateContest: 'Update contest'
  },

  options: {
    course1: 'Course 1',
    course2: 'Course 2',
    course3: 'Course 3'
  },

  modalAddNew: {
    name: 'Question Name',
    level: 'Level',
    skill: 'Skill',
    time: 'Time(s)',
    action: 'Actions',
    add: 'ADD',
    done: 'done',
    addListening: 'Add listening contest',
    addWriting: 'Add writing contest',
    addReading: 'Add reading contest',
    addSpeaking: 'Add speaking contest',
    listening: 'Listening',
    reading: 'Reading',
    writing: 'Writing',
    speaking: 'Speaking',
    findQuestion: 'Enter question name',
    beginner: 'Beginner',
    intermediate: 'Intermediate',
    advanced: 'Advanced',

    addStudent: 'Add student',
    findStudent: 'Enter Student Name',
    nameStudent: 'Student name',
    phoneNumber: 'Phone number',

    cancel: 'Cancel'
  },

  update: {
    updateContest: 'Update contest',
    update: 'Update contest',
    updatePractice: 'Update practice'
  },

  preview: {
    speaking: 'Speaking',
    reading: 'Reading',
    writing: 'Writing',
    listening: 'Listening',
    name: 'Name',
    overall: 'Overall',
    time: 'Time',
    action: 'Action',
    no: 'No',
    practice: 'Practice',
    test: 'Test',
    preview: 'Preview',
    leaderBoard: 'LeaderBoard',
    participants: 'Participant list'
  },

  type: {
    private: 'Private',
    public: 'Public'
  },

  status: {
    ended: 'Ended',
    upcoming: 'Upcoming',
    ongoing: 'Ongoing',
    draft: 'Draft',
    completed: 'Completed',

    test: 'Test',
    practice: 'Practice'
  },

  student: {
    deleteStudent: 'Delete student',
    doYouWant: 'Do you want delete this student?'
  }
};

import request from 'services/request';

export const changePasswordsApi = async (data) =>
  request({
    url: '/reset-password-profile',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const getListContestEnteredApi = async (params) =>
  request({
    url: '/competition/joined',
    method: 'GET',
    params
  });

export const getStudentProfile = async (id) =>
  request({
    url: `/api/student-profile/${id}/`,
    method: 'GET',
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const changStudentProfile = async (id, data) =>
  request({
    url: `/api/student/${id}/`,
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const changePasswordStudentProfile = async (data) =>
  request({
    url: '/api/auth/change-password/',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

import styled from 'styled-components';

export const Label = styled.span`
  height: 100%;
  display: block;
  &.forbiden {
    cursor: not-allowed;
    touch-action: none;
  }
  &.loading {
    cursor: wait;
  }
`;

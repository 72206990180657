import styled from 'styled-components';

export const Wrapper = styled.section`
  position: relative;

  padding-right: 24px;
  cursor: pointer;

  .ant-badge {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

import {
  loginApi,
  registerApi,
  getMeApi,
  checkVerifyKeyAPI,
  getVerifyKeyAPI,
  getUserProfileApi
} from 'services/apis/other/auth';
import { getMyPermissionApi } from 'services/apis/admin/permissionGroup';

import webCookiesStorage from 'utils/webCookiesStorage';

import { ROUTES_AUTH } from 'routes/constants';
import { USER_INFO } from 'constants/configs';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const login = createAsyncThunk(
  'auth/LOGIN',
  async (data, { rejectWithValue }) => {
    try {
      const res = await loginApi(data);
      return res?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVerifyKey = createAsyncThunk(
  'auth/GET_VERIFY_KEY',
  async (data, { rejectWithValue }) => {
    try {
      const res = await getVerifyKeyAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkVerifyKey = createAsyncThunk(
  'auth/VERIFY',
  async (data, { rejectWithValue }) => {
    try {
      const res = await checkVerifyKeyAPI(data);
      return { ...res.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const register = createAsyncThunk(
  'auth/REGISTER',
  async (data, { rejectWithValue }) => {
    try {
      const res = await registerApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMe = createAsyncThunk('auth/GET_ME', async () => {
  const response = await getMeApi();
  return response.data;
});

export const getUserProfile = createAsyncThunk(
  'auth/GET_USER_PROFILE',
  async (wsname, { rejectWithValue }) => {
    try {
      const res = await getUserProfileApi(wsname);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMyPermission = createAsyncThunk(
  'auth/GET_MY_PERMISSION',
  async () => {
    const res = await getMyPermissionApi();
    return res;
  }
);
const initialState = {
  currentUser: {},
  userProfile: {},
  isAuth: webCookiesStorage.getToken() ? true : false,
  isFirstAccess: true,
  loading: false,
  loadingProfile: false,
  error: {},
  isRemember: false,
  userInfo: webCookiesStorage.get(USER_INFO) || {},
  myPermission: {}
};

const authSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    logOut: (state) => {
      state.isAuth = false;
      state.currentUser = {};
      webCookiesStorage.removeAll();
      window.location.replace(ROUTES_AUTH.LOGIN);
    },
    updateIsFirstAccess: (state) => {
      state.isFirstAccess = false;
    },
    resetState: (state, action) => {
      const fieldName = action.payload;
      state[fieldName] = initialState[fieldName];
    },

    setIsRemember: (state, action) => {
      state.isRemember = action.payload;
    },

    actionUpdateAvatar: (state, action) => {
      state.userInfo = {
        ...state.userInfo,
        avatar: action.payload
      };
      webCookiesStorage.set(USER_INFO, {
        ...state.userInfo,
        avatar: action.payload
      });
    }
  },

  extraReducers: {
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.isFirstAccess = false;
      state.error = action.payload?.message;
    },
    [login.fulfilled]: (state, action) => {
      state.isAuth = true;
      state.isFirstAccess = true;
      state.loading = false;
      state.error = {};
      state.userInfo = action?.payload;
      webCookiesStorage.set(USER_INFO, action?.payload);
      webCookiesStorage.setToken(
        action?.payload?.tokens?.access,
        state.isRemember ? { expires: 30 } : {}
      );
      webCookiesStorage.setRefreshToken(
        action?.payload?.tokens?.refresh,
        state.isRemember ? { expires: 30 } : {}
      );
    },

    [getMyPermission.pending]: (state) => {
      state.loading = true;
    },
    [getMyPermission.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getMyPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.myPermission = action?.payload?.data?.permission?.slider_bar;
    }
  }
});

export const {
  updateIsFirstAccess,
  setIsRemember,
  setAuth,
  logOut,
  actionUpdateAvatar
} = authSlice.actions;
const { reducer: authReducer } = authSlice;
export default authReducer;

export default {
  stt: 'No',
  employeeManager: 'Quản lý nhân viên',
  nameEmployee: 'Tên nhân viên ',
  email: 'Email',
  phoneNumber: 'Số điện thoại',
  enterEmployee: 'Nhập tên / email / sđt nhân viên',
  addNew: 'Thêm mới',
  addEmployee: 'Thêm mới nhân viên',
  enterName: 'Nhập tên',
  actions: 'Hành động',
  enterPhoneNumber: 'Nhập số điện thoại',
  enterEmail: 'Nhập email',
  passwordDefault: 'MẬT KHẨU MẶC ĐỊNH: tes@2023',
  addNewEmployee: 'Thêm mới nhân viên',
  deleteEmployee: 'Xoá nhân viên',
  doYouWant: 'Bạn có muốn xóa nhân viên này?',
  editEmployee: 'Chỉnh sửa thông tin nhân viên',
  edit: 'Chỉnh sửa',
  pleaseEnterName: 'Vui lòng nhập tên nhân viên',
  nameIsNot: 'Tên không được vượt quá 256 ký tự',
  pleaseEnterEmail: 'Vui lòng nhập email',
  pleaseEnterPhone: 'Vui lòng nhập số điện thoại',
  enterAValid: 'Email không đúng định dạng!',
  minPhone: 'Điện thoại di động có ít nhất 10 số',
  maxPhone: 'Điện thoại di động có tối đa 11 số',
  wrongPhoneFormat: 'Số điện thoại không đúng định dạng',
  addEmployeeFalied: 'Thêm nhân viên thất bại',
  addEmployeeSuccess: 'Thêm nhân viên thành công',
  getListFalied: 'Lấy danh sách nhân viên thất bại',
  editEmployeeFalied: 'Chỉnh sửa thông tin nhân viên thất bại',
  editEmployeeSuccess: 'Chỉnh sửa thông tin nhân viên thành công',
  maxEmail: 'Email không vuợt quá 50 ký tự',
  invalidEmail: 'Email không hợp lệ',
  permission: 'Nhóm quyền'
};

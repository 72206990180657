import styled, { css } from 'styled-components';
import { Layout as La } from 'antd';
import media from 'styles/breakpoints';

const { Header } = La;

export const Hd = styled(Header)`
  display: flex;
  background: #ffffff;
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 16px 120px;
  height: 80px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.17);

  ${media.desktop`
    padding: 16px 80px;
  `}

  ${media.tablet`
    padding: 12px 40px;
    height: 60px;
  `}

  ${media.phone`
    padding: 8px 25px;
  `}

  ${media.smallPhone`
    padding: 8px 15px;
  `}
`;

export const Layout = styled(La)`
  min-height: 100vh;
  background: #f2f2f2 !important;
  padding: 112px 120px 0px;

  ${media.desktop`
    padding: 112px 80px 0px;
  `}

  ${media.tablet`
    padding: 90px 40px 0px;
  `}

  ${media.phone`
    padding: 75px 25px 0px;
  `}

  ${media.smallPhone`
    padding: 75px 15px 0px;
  `}
`;

export const Logo = styled.div`
  width: 200px;
  align-self: center;

  ${media.tablet`
    width: 160px;
  `}

  ${media.phone`
    display: none;
  `}

  img {
    cursor: pointer;
    object-fit: cover;
    border-radius: 4px;
    width: 200px;

    ${media.tablet`
      width: 160px;
    `}
  }
`;

export const MenuHeadWrap = styled.div`
  display: flex;
  margin: 0px 100px;

  ${media.desktop`
    margin: 0px 80px;
  `}

  ${media.tablet`
    margin: 0px 40px;
  `}

  ${media.phone`
    margin: 0px;
  `}
`;

export const MenuHeadItem = styled.div`
  margin-left: 24px;
  cursor: pointer;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme?.colors?.mainColor : theme?.colors?.textGray};
  font-size: ${({ theme }) => theme?.fontSize?.base};
  font-weight: ${({ theme }) => theme?.fontWeight?.extraBold};
  line-height: 24px;
  align-self: center;
  position: relative;

  ${media.phone`
    margin-left: 0px;
    margin-right: 16px;
  `}

  &::after {
    content: '';
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: -8px;
    left: 5%;
    background: #ffb00a;
    transition: all 0.4s ease 0s;
  }

  &:hover {
    &::after {
      width: 90%;
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      &::after {
        width: 90%;
      }
    `}
`;

export const UserWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  ${media.tablet`
    .ant-avatar {
      width: 35px !important;
      height: 35px !important;
      img {
        width: 35px;
        height: 35px;
      }
    }
  `}
`;

export const UserName = styled.p`
  color: ${({ theme }) => theme?.colors?.textGray};
  text-align: right;
  font-size: ${({ theme }) => theme?.fontSize?.base};
  font-weight: ${({ theme }) => theme?.fontWeight?.extraBold};
  padding-right: 8px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${media.smallPhone`
    display: none;
  `}
`;

export const NotificationWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`;

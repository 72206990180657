export default {
  resetPassword: 'Change password',
  newPassword: 'New Password',
  inputNewPassword: 'Confirm new Password',
  update: 'Update',
  pleaseInputPass: 'Please enter your password',
  wrongPasswordFormatMin: 'Password contains at least 6 characters',
  wrongPasswordFormatMax: 'Password is not exceeded 20 characters',
  changePasswordFalied: 'Password change failed',
  changePasswordSuccess: 'Change passwords success',
  passWordDoNotMatch: 'New password do not match',
  invalidPass: 'Password without spaces'
};

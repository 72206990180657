import request from 'services/request';

export const getListNotificationApi = async params =>
  request({
    url: '/api/notifications/',
    method: 'GET',
    params
  });

export const updateFCMToken = async (id, data) =>
  request({
    url: `/api/auth/${id}/fcm-token/`,
    method: 'PUT',
    data
  });

export default {
  USERPROFILE__EMAIL__EMPTY: 'Email is empty',
  USERPROFILE__EMAIL__REQUIRED: 'Please enter Email',
  USERPROFILE__EMAIL__LENGTH: 'Email is too long',
  USERPROFILE__EMAIL__INVALID_FORMAT: 'Invalid Email',
  EXTERNAL_APP_CONFIG__RESTORE__SUCCESS: 'Restored successfully',
  EXTERNAL_APP_CONFIG__WORKSPACE__EXISTS: 'Existed',
  EXTERNAL_APP_CONFIG__CREATE__SUCCESS: 'Created successfully',
  ROLE__OWNER__CAN_NOT_UPDATE: 'Cannot update role',
  ROLE__OWNER__CAN_NOT_DELETE: 'Cannot delete role',
  ROLE__HAS_STAFF__CAN_NOT_DELETE: 'Cannot delete role which contains staff',
  ROLE__PERMISSIONS__INVALID: 'Invalid role',
  INVITATION_CODE__RESOURCE__TO_MUCH: 'Invalid data',
  INVITATION_CODE__BOOK__EXISTS: 'Existed code',
  INVITATION_CODE__COURSE_ID__INVALID: 'Invalid course code',
  INVITATION_CODE__STUDENT_ID__INVALID: 'Invalid student code',
  INVITATION_CODE__RESOURCE__INVALID: 'Invalid data',
  INVITATION_CODE__CREATE__SUCCESS: 'Created successfully',
  CODE__REQUIRED: 'Enter code',
  STAFF__ROLES_OWNER__REQUIRED: 'This requires an owner role',
  STAFF__ROLES__INVALID: 'Invalid role',
  RESEND__EMAIL__SUCCESS: 'Sent successfully',
  STAFF__UPDATE_PERMISSIONS__SUCCESS: 'Updated successfully',
  STAFF__PERMISSIONS__INVALID: 'Invalid role',
  STAFF__DELETE_PERMISSIONS__SUCCESS: 'Deleted successfully',
  STAFF__UPDATE_ROLES__SUCCESS: 'Updated successfully',
  STAFF__DELETE_ROLES__SUCCESS: 'Deleted successfully',
  STAFF__FULL_NAME__EMPTY: 'Name is empty',
  STAFF__FULL_NAME__REQUIRED: 'Enter name',
  STAFF_INVITATION__EMAIL__EMPTY: 'Email is empty',
  STAFF_INVITATION__EMAIL__REQUIRED: 'Please enter Email',
  STAFF_INVITATION__EMAIL__LENGTH: 'Email is too long',
  STAFF_INVITATION__EMAIL__INVALID_FORMAT: 'Invalid Email',
  USER__EMAIL__NOT_SET: 'User have no Email yet',
  STAFF_INVITATION__ACCEPT__ACCEPTED: 'Confirmed',
  STAFF_INVITATION__ACCEPT__EXPIRE: 'Confirm is expired',
  STAFF_INVITATION__ACCEPT__SUCCESS: 'Confirmed successfully',
  STAFF_INVITATION__ACCEPT__FAILED: 'Confirmed failed',
  BETA_INFO__REGISTER__SUCCESS: 'Registered successfully',
  BETA_INFO__EMAIL__EMPTY: 'Email is empty',
  BETA_INFO__EMAIL__REQUIRED: 'Please enter Email',
  BETA_INFO__EMAIL__LENGTH: 'Email is too long',
  BETA_INFO__EMAIL__INVALID_FORMAT: 'Invalid Email',
  CONTACT__CREATE__SUCCESS: 'Sent successfully',
  CONTACT__EMAIL__EMPTY: 'Email is empty',
  CONTACT__EMAIL__REQUIRED: 'Please enter Email',
  CONTACT__EMAIL__LENGTH: 'Email is too long',
  CONTACT__EMAIL__INVALID_FORMAT: 'Invalid Email',
  COURSES__STUDENT_ID__INVALID: 'Invalid student',
  COURSES__LEARNING_STATUS__INVALID: 'Invalid study status',
  COURSES__ACTIVE_STATUS__INVALID: 'Invalid status',
  COURSES__UPDATE__SUCCESS: 'Updated successfully',
  TRACKING__DATE_INPUT__INVALID: 'Invalid date',
  COURSE_LESSONS__ID__INVALID: 'Invalid lesson',
  COURSE_LESSONS__UPDATE_ORDER__SUCCESS: 'Order successfully',
  COURSE_LESSONS__INPUT__INVALID: 'Invalid',
  COURSES_LESSON_DOCUMENT__ASSETS_ID__REQUIRED: 'File is empty',
  COURSES_LESSON_DOCUMENT__ACTION_INVALID: 'Invalid action',
  COURSES_LESSON_DOCUMENT__ASSETS_ID__INVALID: 'Invalid file',
  COURSES_LESSON__STATUS__INVALID: 'Invalid status',
  COURSES_LESSON__END_AT__INVALID: 'Invalid end time',
  COURSES_LESSON__START_AT__INVALID: 'Invalid start time',
  COURSES_LESSON__NAME__UNIQUE: 'Existed name',
  COURSES_LESSON__NAME__EMPTY: 'Name is empty',
  COURSES_LESSON__NAME__REQUIRED: 'Please enter name',
  COURSES__CODE__INVALID: 'Invalid code',
  COURSES__CODE__NOT_FOR_STUDENT: 'This code is not used for this student',
  CLASS__JOIN__SUCCESS: 'Joined successfully',
  CLASS__JOIN__FAILED: 'Joined failed',
  COURSE__STATUS__INVALID: 'Incorrect course status',
  BOOK__USERPROFILE_ID__INVALID: 'Incorrect reader',
  BOOK__ADD_READER__SUCCESS: 'Added reader successfully',
  BOOK__DELETE_READER__SUCCESS: 'Deleted reader successfully',
  ASSETS__SHARING__DISABLE: 'Share is temporarily locked.',
  PLAYLIST__NAME__REQUIRED: 'Please enter playlist name',
  PLAYLIST__NAME__EMPTY: 'Playlist name is empty',
  PLAYLIST__CODE__UNIQUE: 'Existed code',
  PLAYLIST__NAME__UNIQUE: 'Existed name',
  ASSETS__INVITATION_CODE__EXISTS: 'Existed code',
  ASSETS__SHARING__EMPTY: 'Invalid share',
  ASSETS__PATH_FILE__TOO_LARGE: 'File is too large',
  ASSETS__APP_TYPE__INVALID: 'Invalid app type',
  ASSETS__PARENT__INVALID: 'Incorrect folder',
  REGISTER__INFORMATION__SUCCESS: 'Registered successfully',
  INPUT__ADMIN_TOKEN__REQUIRED: 'Please enter admin token',
  INPUT__WORKSPACE_DISPLAY_NAME__REQUIRED: 'Please enter workspace name',
  INPUT__WORKSPACE_NAME__REQUIRED: 'Please enter workspace',
  INPUT__OWNER_EMAIL__REQUIRED: 'Please enter Email',
  INPUT__FULL_NAME__REQUIRED: 'Please enter name',
  INPUT__OWNER_PHONE_NUMBER__REQUIRED: 'Please enter phone number',
  INPUT__OWNER_PASSWORD__REQUIRED: 'Please enter password',
  INPUT__ADMIN_TOKEN__EMPTY: 'Admin token is empty',
  INPUT__WORKSPACE_DISPLAY_NAME__EMPTY: 'Workspace name is empty',
  INPUT__WORKSPACE_NAME__EMPTY: 'Workspace is empty',
  INPUT__OWNER_EMAIL__EMPTY: 'Email is empty',
  INPUT__FULL_NAME__EMPTY: 'Name is empty',
  INPUT__OWNER_PHONE_NUMBER__EMPTY: 'Phone number is empty',
  INPUT__OWNER_PASSWORD__EMPTY: 'Password is empty',
  INPUT__OWNER_EMAIL__INVALID: 'Invalid Email format',
  INPUT__OWNER_PHONE_NUMBER__UNIQUE: 'Existed phone number',
  INPUT__ADMIN_TOKEN__INVALID: 'Incorrect admin token',
  INPUT__WORKSPACE_NAME__UNIQUE: 'Existed Workspace',
  USER__EMAIL__UNINVITED:
    'The account has not been invited to join the system.',
  USER__ACCOUNT__DEACTIVATED: 'Account was deactivated',
  USER__LOGIN__UNAVAILABLE: 'Login feature í temporarily unavailable',
  USER__VERIFY_KEY__BLOCK: 'Log in more than allowed',
  USER__DELETE__FAILED: 'Deleted account failed',
  USER_DELETE_SUCCESS: 'Deleted account successfully',
  USER__ACCOUNT__INCORRECT: 'Account or password is incorrect.',
  STUDENT__DELETE__SUCCESS: 'Deleted successfully',
  STUDENT__NAME__EMPTY: 'Name is empty',
  STUDENT__NAME__REQUIRED: 'Please enter name',
  STUDENT__DOB__INVALID_FORMAT: 'Invalid date format',
  USER__PHONE_NUMBER__INVALID: 'Phone number is not existed.',
  USER__PHONE_NUMBER__INCORRECT: 'Phone number is not confirmed',
  USER__VERIFY_KEY__SENDING: 'Sent code successfully',
  USER__CREATE__SUCCESS: 'Registered successfully',
  USER__PASSWORD__INVALID: 'Confirm password does not match with password',
  USER__ACCOUNT__DEACTIVATE: 'Account was deactivated.',
  USER__VERIFY_KEY__EXPIRED: 'OTP is expired! Please enter another code.',
  USER__VERIFY_KEY__INCORRECT: 'Incorrect OTP! Please enter another code.',
  USER__AVATAR__REQUIRED: 'Please upload avatar',
  BOOK__CREATE__SUCCESS: 'Added book successfully.',
  BOOK__UPDATE__SUCCESS: 'Updated book successfully.',
  USER__EMAIL__LENGTH: 'Email is not exceeded 52 characters',
  USER__BIRTHDAY__INVALID_FORMAT: 'Incorrect date format',
  USER__BIRTHDAY__INVALID: 'Invalid date',
  USER__OLD_PASSWORD__EMPTY: 'Please enter confirmation password',
  USER__OLD_PASSWORD__REQUIRED: 'Please re-enter password',
  USER__NEW_PASSWORD__EMPTY: 'Please enter new password',
  USER__NEW_PASSWORD__REQUIRED: 'Please enter new password',
  USER__NEW_PASSWORD_CONFIRM__EMPTY: 'Please enter confirmation password',
  USER__NEW_PASSWORD_CONFIRM__REQUIRED: 'Please enter new password',
  USER__SUID__INVALID: 'Invalid sub user',
  USER__FIELD__UNIQUE: 'Existed data',
  BOOK__NAME__EMPTY: 'Book name is empty',
  BOOK__NAME__REQUIRED: 'Please enter book name',
  PLAYLIST__ASSETS_ID__INVALID: 'Incorrect book ID',
  BOOK__ASSETS_ID__INVALID: 'Incorrect file ID',
  BOOK__USER_PROFILE_ID__INVALID: 'Incorrect user profile',
  COURSE__NAME__EMPTY: 'Course name is empty',
  COURSE__NAME__REQUIRED: 'Please enter course name',
  COURSE__NAME__UNIQUE: 'Existed course name',
  COURSE__ASSETS_ID__INVALID: 'Invalid course ID',
  COURSE__STUDENT_ID__INVALID: 'Invalid student ID',
  WORKSPACE__NAME__EMPTY: 'Workspace name is empty',
  WORKSPACE__NAME__REQUIRED: 'Please enter workspace name',
  WORKSPACE__NAME__UNIQUE: 'Existed workspace name',
  WORKSPACE__WORKSPACE__UNIQUE: 'Workspace is existed',
  WORKSPACE__WORKSPACE__EMPTY: 'Workspace is empty',
  WORKSPACE__WORKSPACE__REQUIRED: 'Please enter workspace',
  WORKSPACE__CREATE__FAILED: 'Created workspace failed',
  USER_PROFILE__DOB__INVALID_FORMAT: 'Incorrect date of birth format',
  INVITATION_CODE__BOOK_ID__INVALID: 'Incorrect book code',
  INVITATION_CODE__DELETE__SUCCESS: 'Deleted successfully',
  STUDENT__UPDATE__SUCCESS: 'Updated successfully',
  STUDENT__CREATE__SUCCESS: 'Created successfully',
  USER_PROFILE__UPDATE__SUCCESS: 'Updated successfully',
  WORKSPACE__DELETE__SUCCESS: 'Deleted successfully',
  WORKSPACE__UPDATE__SUCCESS: 'Updated successfully',
  WORKSPACE__CREATE__SUCCESS: 'Created successfully',
  COURSE__ADD_STUDENT__SUCCESS: 'Added successfully',
  COURSE__ADD_ASSETS__SUCCESS: 'Added successfully',
  BOOK__DELETE__SUCCESS: 'Deleted successfully',
  COURSE__UPDATE__SUCCESS: 'Updated successfully',
  COURSE__CREATE__SUCCESS: 'Created successfully',
  BOOK__INVITE_USER__SUCCESS: 'Added successfully',
  BOOK__ADD_ASSETS__SUCCESS: 'Added successfully',
  COURSE__DELETE_SUCCESS: 'Deleted successfully',
  USER_CREATE_SUCCESS: 'Email updated successfully',
  VERIFY_KEY__INPUT__INVALID: 'Incorrect verify code',
  INVITATION_CODE__CODE__EXISTS: 'Code is used',
  COURSE__DELETE__SUCCESS: 'Deleted successfully',
  ASSETS__ADD_INVITATION_CODE__SUCCESS: 'Created successfully',
  USER__VERIFY_KEY__INVALID: 'Incorrect verify code',
  ASSETS__INVITATION_CODE__INVALID: 'Incorrect code.',
  ASSETS__INVITATION_CODE__VERIFIED: 'Verified code successfully.',
  USERPROFILE__DOB__FORMAT_INVALID: 'Invalid date of birth',
  STAFF__CREATE__SUCCESS: 'Add new staff successfully',
  STAFF__DELETE__SUCCESS: 'Deleted staff successfully',
  ROLE__DELETE__SUCCESS: 'Deleted permission successfully',
  ROLE__CREATE__SUCCESS: 'Add new permission successfully',
  ROLE__DELETE__FAILED: 'You are not allowed to delete this permission',
  STAFF__INVITATION__SUCCESS: 'Invitation sent successfully',
  ROLE__UPDATE__SUCCESS: 'Updated permission successfully',
  STAFF_INVITATION__INVITED__BEFORE: 'Staff was invited',
  STAFF__UPDATE__SUCCESS: 'Updated staff successfully',
  SHARE_LIST_ASSETS__CREATE__SUCCESS: 'Created successfully',
  SHARE_ASSETS__UPDATE__SUCCESS: 'Updated playlist successfully',
  ASSETS__CODE__VERIFIED: 'Verified code successfully.',
  PLAYLIST__DELETE__SUCCESS: 'Deleted playlist successfully',
  PLAYLIST__UPDATE__SUCCESS: 'Updated playlist successfully',
  WORKSPACE__CREATE__NOT_SUPPORT_ON_BETA_VERSION:
    'This feature was limited in the beta version',
  BOOK__CREATE_ORDER_ASSETS__SUCCESS: 'Changed position successfully',
  BOOK__UPDATE_ORDER_ASSETS__SUCCESS: 'Changed position successfully',
  COURSE__CREATE_ORDER_ASSETS__SUCCESS: 'Changed position successfully',
  COURSE__UPDATE_ORDER_ASSETS__SUCCESS: 'Changed position successfully',
  COURSES__ADD_STUDENT__SUCCESS: 'Added successfully',
  COURSES__DELETE_STUDENT__SUCCESS: 'Deleted successfully',
  COURSES__ADD_ASSETS__SUCCESS: 'Added successfully',
  COURSES__DELETE_ASSETS__SUCCESS: 'Deleted successfully',
  BOOK__DELETE_ASSETS__SUCCESS: 'Deleted successfully',
  PLAYLIST__DELETE_ASSETS__SUCCESS: 'Deleted successfully',
  PLAYLIST__ADD_ASSETS__SUCCESS: 'Added successfully',
  COURSES_LESSON__DELETE__SUCCESS: 'Deleted lesson successfully',
  COURSES_LESSON_EXERCISE__ADD__SUCCESS: 'Created lesson successfully',
  COURSES_LESSON_EXERCISE__DELETE__SUCCESS: 'Deleted exercise successfully',
  COURSES_LESSON__UPDATE__SUCCESS: 'Updated lesson successfully',
  COURSES_LESSON_EXERCISE__UPDATE__SUCCESS: 'Updated exercise successfully',
  ASSETS__NAME__UNIQUE: 'Please enter another name',
  COURSES_LESSON__ADD__SUCCESS: 'Created lesson successfully',
  EXERCISES__UPDATE__SUCCESS: 'Updated exercise successfully',
  COURSES_LESSON_DOCUMENT__ADD__SUCCESS: 'Added file successfully',
  COURSES_LESSON_DOCUMENT__DELETE__SUCCESS: 'Deleted file successfully',
  COURSES_LESSON_DOCUMENT__UPDATE__SUCCESS: 'Updated file successfully',
  ANSWER_EXERCISES__UPDATE__SUCCESS: 'Pointed sucessfully',
  COURSES_LESSON__LEARNING_CONTENT__EMPTY: 'Lesson content is empty',
  ATTENDANCE__UPDATE__SUCCESS: 'Updated attendance information successfully',
  COURSE_LESSONS__MULTI_CREATE__SUCCESS: 'Created lesson successfully',
  ROLE__UPDATE_PERMISSIONS__SUCCESS: 'Updated permission successfully',
  ROLE__DUPLICATED__SUCCESS: 'Copied permission successfully',
  CANT_DELETE_ROLE_HAS_STAFF: 'Deleted permission failed',
  PLAYLIST__CREATE__SUCCESS: 'Added new playlist successfully',
  BOOK__NAME__UNIQUE: 'Please enter another book name',
  ROLE__NAME__REQUIRED: 'Please enter name',
  ROLE__NAME__EMPTY: 'Name is empty',
  ROLE__NAME__UNIQUE: 'Existed name',
  ROLE__NAME__INVALID_ROLE_NAME: 'Invalid name',
  STAFF__ROLE__CAN_NOT_DELETE: 'Cannot delete role',
  STAFF_INVITATION__EMAIL__INVITED_BEFORE: 'This Email was invited',

  PLAYLIST__CODE__VERIFIED: 'Verify successfully',
  JOIN__BOOK__BEFORE: 'Joined before',
  JOIN__BOOK__SUCCESS: 'Join successfully',
  JOIN__COURSE__FAILURE: 'Join failure',
  JOIN__COURSE__BEFORE: 'Joined before',
  JOIN__COURSE__SUCCESS: 'Join successfully',
  PLAYLIST__UPDATE_ORDINAL__SUCCESS: 'Sort successfully',
  BOOK__UPDATE_ORDINAL__SUCCESS: 'Sort successfully',
  COURSE__UPDATE_ORDINAL__SUCCESS: 'Sort successfully',
  EXAM__UPDATE_ORDINAL__SUCCESS: 'Sort successfully',
  ASSETS__FOLDER__CAN_NOT_SHARING: 'Can not share folder',

  // assets
  ASSETS__NAME__EMPTY: 'Empty file name',
  ASSETS__NAME__REQUIRED: 'File name is required',
  ASSETS__NAME__MAX_LENGTH: 'File name is too long',
  ASSETS__NAME__MIN_LENGTH: 'File name is too short',
  ASSETS__LEVEL__EMPTY: 'Empty asset level',
  ASSETS__LEVEL__REQUIRED: 'Asset level is required',
  ASSETS__LEVEL__INVALID: 'Invalid asset level',
  ASSETS__SKILL__EMPTY: 'Empty asset skill',
  ASSETS__SKILL__REQUIRED: 'Asset skill is required',
  ASSETS__SKILL__INVALID: 'Invalid asset skill',
  ASSETS__FILE__REQUIRED: 'File is required',
  ASSETS__CREATE__SUCCESS: 'Asset created successfully',
  ASSETS__UPDATE__SUCCESS: 'Asset updated successfully',
  ASSETS__DELETE__SUCCESS: 'Asset deleted successfully',
  ASSETS__DELETE__FAILURE: 'Failed to delete asset',

  // auth + teacher
  USER__EMAIL__EMPTY: 'Empty email',
  USER__EMAIL__REQUIRED: 'Email is required',
  USER__EMAIL__MAX_LENGTH: 'Email is too long',
  USER__EMAIL__MIN_LENGTH: 'Email is too short',
  USER__EMAIL__UNIQUE: 'Email already exists',
  USER__EMAIL__INVALID: 'Invalid email format',
  USER__EMAIL__NOT_EXIST: 'Email does not exist',
  USER__FIRST_NAME__EMPTY: "Empty user's first name",
  USER__FIRST_NAME__REQUIRED: "User's first name is required",
  USER__FIRST_NAME__MAX_LENGTH: "User's first name is too long",
  USER__FIRST_NAME__MIN_LENGTH: "User's first name is too short",
  USER__PHONE_NUMBER__EMPTY: 'Empty phone number',
  USER__PHONE_NUMBER__REQUIRED: 'Phone number is required',
  USER__PHONE_NUMBER__MAX_LENGTH: 'Phone number is too long',
  USER__PHONE_NUMBER__MIN_LENGTH: 'Phone number is too short',
  USER__PHONE_NUMBER__UNIQUE: 'Phone number already exists',
  USER__PASSWORD__EMPTY: 'Empty password',
  USER__PASSWORD__REQUIRED: 'Password is required',
  USER__PASSWORD__MAX_LENGTH: 'Password is too long',
  USER__PASSWORD__MIN_LENGTH: 'Password is too short',
  USER__TOKEN__EXPIRED_OR_INVALID: 'Token has expired or is invalid',
  USER__TOKEN__REQUIRED: 'Token is required',
  USER__TOKEN__EXPIRED: 'Token is expired',
  USER__TOKEN__INVALID: 'Invalid token',
  USER__REQUEST__BLOCK: 'Request limit exceeded',
  USER__ACCOUNT__INVALID: 'Invalid account',
  USER__VERIFY_TOKEN__NOT_FOUND: 'Verification token not found',
  USER__BIRTHDAY__FORMAT_INVALID: 'Invalid date format for birthday',
  USER__REGISTER__SUCCESS: 'Registration successful',
  USER__UPDATE__SUCCESS: 'Update successful',
  USER__SEND__SUCCESS: 'Sent successfully',
  USER__CHANGE_PASSWORD__SUCCESS: 'Password changed successfully',
  USER__DELETE__SUCCESS: 'Deleted successfully',
  USER__IS_ACTIVE__FALSE: 'User is active false',
  // question
  QUESTION__NAME__EMPTY: 'Empty question name',
  QUESTION__NAME__REQUIRED: 'Question name is required',
  QUESTION__NAME__MAX_LENGTH: 'Question name is too long',
  QUESTION__NAME__MIN_LENGTH: 'Question name is too short',
  QUESTION__TYPE__EMPTY: 'Empty question type',
  QUESTION__TYPE__REQUIRED: 'Question type is required',
  QUESTION__TYPE__INVALID: 'Invalid question type',
  QUESTION__SPEAKING__INVALID: 'Invalid speaking question',
  QUESTION__WRITING__INVALID: 'Invalid writing question',
  QUESTION__LISTENING__INVALID: 'Invalid listening question',
  QUESTION__READING__INVALID: 'Invalid reading question',
  QUESTION__CREATE__SUCCESS: 'Question created successfully',
  QUESTION__UPDATE__SUCCESS: 'Question updated successfully',
  QUESTION__DELETE__SUCCESS: 'Question deleted successfully',
  QUESTION__DELETE__FAILURE: 'Failed to delete question',

  AssetFolders__NAME__EMPTY: 'Empty folder name',
  AssetFolders__NAME__REQUIRED: 'Folder name is required',
  AssetFolders__NAME__MAX_LENGTH: 'Folder name is too long',
  AssetFolders__NAME__MIN_LENGTH: 'Folder name is too short',
  AssetFolders__parent__invalid: 'Invalid parent folder',
  ASSETFOLDERS__CREATE__SUCCESS: 'Folder created successfully',
  ASSETFOLDERS__UPDATE__SUCCESS: 'Folder updated successfully',
  ASSETFOLDERS__DELETE__SUCCESS: 'Folder deleted successfully',
  ASSETFOLDERS__DELETE__FAILURE: 'Failed to delete folder',
  ASSETFOLDERS__UPDATE__FAILURE: 'Failed to update folder',

  // exam
  EXAMS__NAME__EMPTY: 'Empty exam name',
  EXAMS__NAME__REQUIRED: 'Exam name is required',
  EXAMS__NAME__MAX_LENGTH: 'Exam name is too long',
  EXAMS__NAME__MIN_LENGTH: 'Exam name is too short',
  EXAMS__CREATE__SUCCESS: 'Exam created successfully',
  EXAMS__UPDATE__SUCCESS: 'Exam updated successfully',
  EXAMS__DELETE__SUCCESS: 'Exam deleted successfully',
  EXAMS__DELETE__FAILURE: 'Failed to delete exam',
  EXAMS__ID__INVALID: 'Exam id invalid',

  //student
  ACCOUNT__DISABLE__SUCCESS: 'Account disabled successfully',
  ACCOUNT__ENABLE__SUCCESS: 'Account enabled successfully',
  ACOUNT__ENABLE__FAILURE: 'Failed to enable account',
  ACOUNT__DISABLE__FAILURE: 'Failed to disable account',
  EXAMS__JOIN__BEFORE: 'You have joined before',
  'USER__OLD_PASSWORD__INCORRECT_PASSWORD.': 'Old password is incorrect',

  //user
  CHANGE__PASSWORD__SUCCESS: 'Change password success',
  QUESTION__GRADE__UNDONE: 'This test is being graded',
  PERMISSIONGROUPS__DELETE_TEACHER__SUCCESS:
    'Removing employee from permission group successfully',
  PERMISSIONGROUPS__NAME__UNIQUE: 'Please enter another name',
  PERMISSIONGROUPS__UPDATE__SUCCESS: 'Permission group updated successfully',
  PERMISSIONGROUPS__CREATE__SUCCESS: 'Permission group created successfully',
  PERMISSIONGROUPS__DELETE__SUCCESS: 'Permission group deleted successfully',

  CLASS__CREATE__SUCCESS: 'Class created successfully',
  CLASS__UPDATE__SUCCESS: 'Class updated successfully',
  CLASS__DELETE__SUCCESS: 'Class deleted successfully'
};

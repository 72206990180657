export default {
  btnSearch: 'Search',
  showText: 'Show all questions',
  showNews: 'Show all news',
  textDelete: 'DELETE',
  textInput: 'UPLOAD',
  textDeleteAll: 'DELETE ALL',
  advancedFilters: 'Advance filter',
  textShow: 'Show',
  labelSelectAll: 'Select all',
  addNew: 'Add new',
  filter: 'Filter',

  list: {
    type: 'Type',
    status: 'Status',
    reset: 'Reset'
  }
};

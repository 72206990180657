import React, { Suspense, useEffect, useMemo } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { ROUTES_AUTH } from 'routes/constants';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { getMyPermission } from 'store/other/authSlice';
import webCookiesStorage from 'utils/webCookiesStorage';
import { USER_INFO } from 'constants/configs';

import { data } from 'components/Layout/data';

import Layout from 'components/Layout';
import Loading from 'components/Loading';
import { ROLE } from 'utils/constants/enum';
import Forbiden from 'components/commons/Forbiden';

// eslint-disable-next-line react/prop-types
const AuthRoute = ({ component: Component, ...rest }) => {
  // const dispatch = useDispatch();
  const location = useLocation();
  const dispatch = useDispatch();

  const { myPermission } = useSelector((state) => state.authReducer);

  const userInfo = webCookiesStorage.get(USER_INFO);
  const accessToken = webCookiesStorage.getToken();
  // const isFirstLogin = localStorage.getItem('isFirstLogin');

  // useEffect(() => {
  //   if (isFirstAccess) {
  //     if (accessToken) {
  //       dispatch(getMe());
  //     } else {
  //       dispatch(updateIsFirstAccess());
  //     }
  //   }
  // }, [accessToken, dispatch, isFirstAccess]);

  // useEffect(() => {
  //   if (!isAuth && !isFirstAccess) window.location.replace(ROUTES_AUTH.LOGIN);
  // }, [isAuth, isFirstAccess, currentUser]);

  // useEffect(() => {
  //   if (accessToken && isFirstLogin === '1') history.push(ROUTES.UPDATE_EMAIL);
  // }, [accessToken, history, isFirstLogin]);

  // if (isFirstAccess) return <Loading />;

  useEffect(() => {
    if (!accessToken) {
      window.location.replace(ROUTES_AUTH.LOGIN);
    } else {
      if (_.isEmpty(myPermission)) {
        dispatch(getMyPermission());
      }
    }
  }, [accessToken, myPermission, dispatch]);

  const currentKeyPermision = data?.find(
    (item) => item?.keyword === window.location.pathname?.split('/')?.[2]
  )?.permissionSidebarKey;

  if (
    currentKeyPermision !== 'settings' &&
    !_.isEmpty(myPermission) &&
    !myPermission[currentKeyPermision]
  ) {
    const findShouldRouterPath = data?.find(
      (item) => myPermission[item?.permissionSidebarKey]
    )?.link;

    window.location.replace(findShouldRouterPath || '/admin/settings'); // TODO homepage
  }

  const checkPermission = useMemo(() => {
    const permissionKey =
      data?.find((item) => item?.keyword === location.pathname?.split('/')?.[2])
        ?.permissionSidebarKey || '';

    // 'settings' module has been handle permissions in Setting page, so it should be avoid at here
    return permissionKey === 'settings' ? true : myPermission[permissionKey];
  }, [myPermission, location]);

  return (
    <Route {...rest}>
      {(userInfo?.role === ROLE.teacher || userInfo?.role === ROLE.admin) &&
      checkPermission ? (
        <Layout>
          <Suspense fallback={<Loading />}>
            <Component />
          </Suspense>
        </Layout>
      ) : (
        <Forbiden />
      )}
    </Route>
  );
};
export default AuthRoute;

import request from 'services/request';

export const getListNotificationsApi = async params =>
  request({
    url: '/api/notifications/',
    method: 'GET',
    params
  });

export const updateStatusNotificationApi = async (id, data) =>
  request({
    url: `/api/notifications/${id}/`,
    method: 'PUT',
    data
  });

const themeColors = {
  // Primary
  purple1: '#4C095D',
  purple2: '#600B76',
  purple3: '#730D8C',
  purple4: '#9439AB',
  purple5: '#7D14FD',

  // Secondary
  blue1: '#3424FF',
  blue2: '#1C585D',
  blue3: '#5547FF',
  blue4: '#695EFF',

  // Tertiary
  yellow2: '#FFDA4E',

  // Text
  text1: '#19171A',
  text2: '#7B7583',
  text3: '#B9B5C2',
  text4: '#000000',
  text5: '#FFFFFF',

  // Background
  background1: '#E2E1E6',
  background2: '#F8F7FA',
  background3: '#FFFFFF',
  background4: '#F9F6FB',
  background5: '#D7E5DC',
  background6: '#CCCCCC',

  // Notification
  green1: '#07D95A',
  green2: '#9CF0BD',
  red1: '#F8183E',
  red2: '#FCA3B2',

  // Gradient
  gradient1: '#D469C9',
  gradient2: '#A040CC',
  gradient3: '#7F26CD',
  gradient4: '#6A15CE',

  // Base
  gray: '#B9B5C2',
  grayDark: '#5A5C63',
  grayLight: '#D9D9D9',

  //Line
  orange: '#ff8a09',

  //Background color tag
  backgroundRed: '#EF494933',
  backgroundOrange: '#FFB67B33',
  backgroundGreen: '#47C75133',
  backgroudGray: '#E9E9E9',
  backgroundBlue: '#04669533',
  backgroundOrageLight: '#FFB00A33',
  backgroundGreenCard: '#E2FFEA',
  backgroundOrangeCard: '#FFE7D4',
  backgroundWriting: '#FFEFE2',
  backgroundReading: '#D4EAD8',
  backgroundSpeaking: '#FFEDF1',
  backgroundListening: '#E5FBFE',
  backgroundMarked: '#DAF4DC',
  backgroundUnmarked: '#FCDBDB'
};

const colors = {
  blueOxford: '#373B53',
  gray1: '#333333',
  bgDrakGray: '#E5E5E5',
  bgGray1: '#f8f8fb',
  bgGray2: '#FAFAFA',
  gray3: '#999999',
  bgGray3: '#F0F0F0',
  grayline: '#EBECF4',
  blue: '#00B4D8',
  redError: '#ff4d4f',
  white: '#fff',
  primaryButtonColor: '#AB3E18',
  grayButtonColor: '#868A96',
  activeColor: '#F6C92B',
  borderColor: '#dce0ea',
  dark: '#303841',
  linkColor: '#868a96',
  disableText: '#868A96',
  grayTextButton: '#626262',
  backgroundAvatar: '#1480e1',
  bgMainColorbutton: '#F9F4F4',
  bgButton1: '#B76E18',
  grayTab: '#929292',
  grayBgTab: '#EEEFF4',
  black: '#000',
  grayBtn: '#C9C9C9',
  bgItem: '#F8F8F8',
  colorInfoGray: '#929292',
  bgBtnGray: '#F4F7F9' /* Color for site */,
  mainColor: '#FFB00A',
  yellow: '#E98E07',
  bgGray: '#666F77',
  textGrayBold: '#666',
  violet: '#2132CD',
  red: '#E11F1F',
  green: '#2EB718',
  greenLight: '#7DB507',
  greenDark: '#1E880C',
  scarlet: '#9C1111',

  // new Color
  blueNew: '#046695',
  grayNew: '#F6F6F6',
  disabledNew: '#E9E9E9',
  orange: '#FFB00A',
  textGray: '#0E3A50',

  // color main
  primaryColor: themeColors.purple2,
  secondaryColor: themeColors.blue2,
  tertiaryColor: themeColors.yellow2,
  textColor: themeColors.text2,
  textBoldColor: themeColors.text1,
  backgroundColor: themeColors.background3,
  shadowColor: themeColors.purple5,
  notificationSuccess: themeColors.green1,
  notificationError: themeColors.red1,
  buttonGradient: `linear-gradient(97.71deg, ${themeColors.gradient1} 13.18%, ${themeColors.gradient2} 42.81%, ${themeColors.gradient3} 76.14%, ${themeColors.gradient4} 97.53%)`,
  buttonActive: themeColors.purple1,
  buttonDisable: themeColors.gray,
  overlay: themeColors.grayLight,
  borderInput: '#e7e7e7',

  //
  other: themeColors,

  //Color tag
  tagRed: '#F21305',
  tagOrange: '#E9912A',
  tagGreen: '#47C751',
  tagGray: '#0E3A50',
  tagBlue: '#046695',
  tagOrangeLight: '#FFB00A',
  tagMarked: '#47C751',
  tagUnmarked: '#F21305',

  //color border upload
  borderUploadColor: '#b3b3b3',
  tagWriting: '#FF8A09',
  tagReading: '#237D26',
  tagSpeaking: '#C0596D',
  tagListening: '#046695',

  //STUDENT CONTEST EXAM
  borderHeadingList: '#CCC',
  backgroundRow: '#F2F2F2',
  colorNumberHeading: '#1A1A1A',
  colorNumberAnswer: '#B3B3B3',
  backgroundMainContest: '#E6F3F4'
};

const fontSize = {
  xs: '12px',
  sm: '14px',
  base: '16px',
  md: '18px',
  lg: '20px',
  xl: '24px',
  xxl: '32px',
  xxxl: '40px'
};

const fontWeight = {
  regular: 400,
  medium: 500,
  bold: 600,
  extraBold: 700,
  black: 800
};

const zIndex = {
  base: 1,
  dropdown: 10,
  backdrop: 50,
  modal: 100
};

const boxShadow = {
  box: '0px 4px 4px rgba(48, 48, 48, 0.25)'
};

const borderRadius = {
  base: '4px',
  md: '8px',
  lg: '25.5px',
  xl: '28px'
};

const padding = {
  pdHeader: '8px 16px',
  pdSideBar: '20px'
};

export default {
  colors,
  fontSize,
  fontWeight,
  zIndex,
  boxShadow,
  borderRadius,
  padding
};

import {
  getListUserNoPermissionApi,
  getListLevelNoPermissionApi,
  getListPositionNoPermissionApi,
  getListSkillNoPermissionApi,
  getListCourseCatalogNoPermissionApi
} from 'services/apis/other/noPermission';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListLevelNoPermission = createAsyncThunk(
  'noPermission/GET_LIST_LEVEL',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListLevelNoPermissionApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListUserNoPermission = createAsyncThunk(
  'noPermission/GET_LIST_USER',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListUserNoPermissionApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListPositionNoPermission = createAsyncThunk(
  'noPermission/GET_LIST_POSITION',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListPositionNoPermissionApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListSkillNoPermission = createAsyncThunk(
  'noPermission/GET_LIST_SKILL',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListSkillNoPermissionApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListCourseCatalogNoPermission = createAsyncThunk(
  'noPermission/GET_LIST_COURSE_CATALOG',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCourseCatalogNoPermissionApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listLevel: [],
  listUser: [],
  listSkill: [],
  listPosition: [],
  listCourseCatalog: [],
  loading: false,
  error: {}
};

const noPermissionSlice = createSlice({
  name: 'noPermission',
  initialState,

  extraReducers: {
    [getListLevelNoPermission.pending]: state => {
      state.loading = true;
    },
    [getListLevelNoPermission.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListLevelNoPermission.fulfilled]: (state, action) => {
      state.listLevel = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListUserNoPermission.pending]: state => {
      state.loading = true;
    },
    [getListUserNoPermission.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListUserNoPermission.fulfilled]: (state, action) => {
      state.listUser = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListPositionNoPermission.pending]: state => {
      state.loading = true;
    },
    [getListPositionNoPermission.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListPositionNoPermission.fulfilled]: (state, action) => {
      state.listPosition = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListSkillNoPermission.pending]: state => {
      state.loading = true;
    },
    [getListSkillNoPermission.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListSkillNoPermission.fulfilled]: (state, action) => {
      state.listSkill = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListCourseCatalogNoPermission.pending]: state => {
      state.loading = true;
    },
    [getListCourseCatalogNoPermission.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCourseCatalogNoPermission.fulfilled]: (state, action) => {
      state.listCourseCatalog = action.payload.data;
      state.loading = false;
      state.error = {};
    }
  }
});

const { reducer: noPermissionReducer } = noPermissionSlice;
export default noPermissionReducer;

export default {
  btnSearch: 'Tìm kiếm',
  showText: 'Hiển thị tất cả câu hỏi',
  showNews: 'Hiển thị tất cả tin tức',
  textDelete: 'XÓA',
  textInput: 'TẢI LÊN',
  textDeleteAll: 'XÓA TẤT CẢ',
  advancedFilters: 'Bộ lọc nâng cao',
  textShow: 'Hiển thị',
  labelSelectAll: 'Chọn tất cả',
  addNew: 'Tạo mới',

  list: {
    type: 'Type',
    status: 'Status',
    reset: 'Lọc'
  }
};

export default {
  resetPassword: 'Đặt lại mật khẩu',
  newPassword: 'Mật khẩu mới',
  inputNewPassword: 'Nhập lại mật khẩu mới',
  update: 'Cập nhật',

  wrongPasswordFormat: 'Mật khẩu sai định dạng',
  pleaseInputPass: 'Vui lòng nhập mật khẩu',
  passWordDoNotMatch: 'Mật khẩu mới không khớp',
  changePassword: 'Thay đổi mật khẩu không thành công'
};

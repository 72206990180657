export default {
  updateEmail: 'Cập nhật Email',
  newpassword: 'Mật khẩu ',
  inputnewpassword: 'Nhập lại mật khẩu ',
  confirmPass: 'Xác nhận mật khẩu',
  saveInfo: 'Lưu thông tin',
  desUpdateEmail:
    'Vui lòng cập nhật thông tin đăng nhập để sử dụng website The English STUDIO',
  email: 'Email',
  plsInputEmail: 'Vui lòng nhập email'
};

import request from 'services/request';

export const getListContestApi = async params =>
  request({
    url: '/competition/home/rule',
    method: 'GET',
    params,
    enableFlashMessageError: false
  });
export const getListCompetitionHomeApi = async params =>
  request({
    url: '/competition/home',
    method: 'GET',
    params,
    enableFlashMessageSuccess: false,
    enableFlashMessageError: false
  });
export const getListQuesAnswersApi = async (id, idR) =>
  request({
    url: `/competition/join?competition_id=${id}&exam_round_id=${idR}`,
    method: 'GET',
    id,
    idR,
    enableFlashMessageSuccess: false,
    enableFlashMessageError: false
  });
export const getListHomeApi = async params =>
  request({
    url: '/home',
    method: 'GET',
    params
  });
export const getListCompetitionHomeDetailApi = async id =>
  request({
    url: `/competition/detail/${id}`,
    method: 'GET',
    enableFlashMessageSuccess: false,
    enableFlashMessageError: false
  });
export const getListCompetitionExamApi = async params =>
  request({
    url: '/competition/result',
    method: 'GET',
    params,
    enableFlashMessageSuccess: false,
    enableFlashMessageError: false
  });
export const postContactApi = async data =>
  request({
    url: '/competition/home/contact',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const postExamResult = async data =>
  request({
    url: '/mark-competition/send-result',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });
export const getRulestApi = async params =>
  request({
    url: '/user/mock-test/rule',
    method: 'GET',
    params
  });
export const getAuditionsApi = async params =>
  request({
    url: '/user/mock-test/detail',
    method: 'GET',
    params
  });
export const getNotificationAuditionsApi = async data =>
  request({
    url: '/user/mock-test/notification-result',
    method: 'PUT',
    data
  });
export const getListAuditonsApi = async data =>
  request({
    url: '/user/mock-test/notification-answer',
    method: 'POST',
    data
  });

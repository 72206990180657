import React from 'react';
import { useHistory, generatePath, useParams } from 'react-router-dom';

import { Label } from './styled';

const MenuLabel = ({ data, children, setCollapsed }) => {
  const { wsname } = useParams();
  const history = useHistory();

  return (
    <Label
      onClick={() => {
        history.push(generatePath(data?.link, { wsname }));
        if (window.screen.width < 769) {
          setCollapsed(true);
        }
      }}
    >
      {children}
    </Label>
  );
};

export default MenuLabel;

export default {
  file: 'File',
  audio: 'Audio',
  link: 'Link Youtube',
  confirm: 'Confirm',
  placeHolderLink: 'Please enter your link',
  pleaseUpload: 'Please upload your audio file',
  pleaseUploadFile: 'Please upload your file',
  onlyAudio: 'Only upload audio files (MP3/WMA/WAV/OGG/...)',
  multiFileType: 'Upload audio or document files'
};

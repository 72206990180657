import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { logOut } from 'store/other/authSlice';
import useTranslation from 'hooks/useTranslation.js';
import { updateFCMToken } from 'services/apis/admin/notification';

import Button from '../Button';

import * as S from './styled';

export default function Forbiden() {
  const { t } = useTranslation('layout');

  const dispatch = useDispatch();
  const history = useHistory();

  const { userInfo } = useSelector(state => state.authReducer);

  return (
    <S.Wrapper>
      <section className="content">
        <span>{t('youDontHavePermission')}</span>
        <div className="button-wrap">
          <Button
            $type="gray"
            className="button"
            onClick={() => history.goBack()}
          >
            {t('back')}
          </Button>
          <Button
            className="button"
            onClick={() => {
              updateFCMToken(userInfo?.id, {
                fcm_token: ''
              });
              dispatch(logOut());
            }}
          >
            {t('logOut')}
          </Button>
        </div>
      </section>
    </S.Wrapper>
  );
}

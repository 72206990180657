import request from 'services/request';

export const getIntroduceApi = async () =>
  request({
    url: '/introduce',
    method: 'GET'
  });

export const updateIntroduceApi = async data =>
  request({
    url: `/introduce/update`,
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import { messageForbiden } from 'utils/helpers/messageForbiden';

import queryString from 'query-string';
import { ROUTES_AUTH } from 'routes/constants';

import ToastMessage from '../components/Toast';
import {
  API_SERVER,
  FORBIDDEN_STATUS_CODE,
  TOKEN_EXPIRED_STATUS_CODE
} from '../constants/configs';
import webCookiesStorage from '../utils/webCookiesStorage';

const baseApiConfig = {
  baseURL: `${API_SERVER}/v1`,
  headers: {
    'content-type': 'application/json'
  },
  timeout: 600000, // 600s = 10 mins
  paramsSerializer: params => queryString.stringify(params)
};

const request = ({
  enableFlashMessageError = true,
  enableFlashMessageSuccess = false,
  isAuth = true,
  isFormData = false,
  ...options
}) => {
  if (isFormData) {
    baseApiConfig.headers = {
      'content-type': 'multipart/form-data'
    };
  } else {
    baseApiConfig.headers = {
      'content-type': 'application/json'
    };
  }

  const baseApiClient = axios.create(baseApiConfig);

  if (isAuth) {
    const accessToken = webCookiesStorage.getToken();
    if (accessToken)
      baseApiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  const onSuccess = response => {
    if (enableFlashMessageSuccess && response?.data?.message) {
      const messageList = [];
      messageList.push(response.data.message);
      toast(
        <ToastMessage
          type="success"
          message={messageList}
          translation="messageStatus"
        />
      );
    }
    return Promise.resolve(response);
  };

  const onError = async error => {
    if (
      error.response.status !== TOKEN_EXPIRED_STATUS_CODE &&
      enableFlashMessageError &&
      error.response?.data?.errors
    ) {
      const messageList = [];
      const errData = error.response.data;
      if (errData.errors && errData.errors.includes('Validation')) {
        Object.values(errData.errors).forEach(errArr => {
          errArr.forEach(err => {
            messageList.push(err);
          });
        });
      } else messageList.push(errData.errors);
      toast(
        <ToastMessage
          type="error"
          message={messageList}
          translation="messageStatus"
        />
      );
    }
    if (error.response.status === TOKEN_EXPIRED_STATUS_CODE) {
      const originalRequest = error.config;
      const refreshToken = webCookiesStorage.getRefreshToken();
      if (refreshToken) {
        try {
          const res = await axios
            .create(baseApiConfig)
            .post('/n/token/refresh/', { refresh: refreshToken });

          webCookiesStorage.setToken(res?.data?.access, { expires: 30 });
          originalRequest.headers.Authorization = `Bearer ${res?.data?.access}`;
          return baseApiClient(originalRequest);
        } catch (_error) {
          webCookiesStorage.removeAll();
          window.location.replace(ROUTES_AUTH.LOGIN);
        }
      } else {
        webCookiesStorage.removeAll();
        window.location.replace(ROUTES_AUTH.LOGIN);
      }
    }
    if (
      error.response.status === FORBIDDEN_STATUS_CODE &&
      webCookiesStorage.getToken()
    ) {
      messageForbiden();
      // eslint-disable-next-line no-restricted-globals
      history.back();
    }
    return Promise.reject(error.response);
  };

  return baseApiClient(options).then(onSuccess).catch(onError);
};

export default request;

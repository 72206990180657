export default {
  advanced: 'Advanced',
  intermediate: 'Intermediate',
  beginner: 'Beginner',
  ended: 'Ended',
  upcoming: 'Upcoming',
  ongoing: 'Ongoing',
  draft: 'Draft',
  private: 'Private',
  public: 'Public',
  undone: 'Undone',
  done: 'Done',
  completed: 'Completed',
  writing: 'Writing',
  speaking: 'Speaking',
  reading: 'Reading',
  listening: 'Listening',
  marked: 'Marked',
  unmarked: 'Unmarked',
  published: 'Submitted',
  unpublished: 'Submit'
};

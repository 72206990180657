export default {
  writing: 'Writing',
  reading: 'Reading',
  speaking: 'Speaking',
  listening: 'Listening',

  question: 'Question',
  answer: 'Answer',
  feedback: 'Teacher’s feedback',
  score: 'Score',
  task: 'Task {{task}}',
  part: 'Part {{part}}',

  yourScoreIs: 'Your Score is',
  correctAnswers: 'Correct Answers',
  timeSpent: 'Time spent',
  answerTable: 'Answer Table',
  examReview: 'Exam Review',
  explanation: 'Explanation',
  thisTaskIsGrading: 'This task is being graded.',
  thisPartIsGrading: 'This part is being graded.',

  breadcrum: {
    writing: 'Writing result',
    reading: 'Reading result',
    speaking: 'Speaking result',
    listening: 'Listening result',
    profile: 'Profile',
    contestManager: 'Contest'
  },

  overView: {
    test: 'Test',
    practice: 'Practice',
    reading: 'Reading',
    listening: 'Listening',
    speaking: 'Speaking',
    writing: 'Writing',
    result: 'Result'
  }
};
